import axios from "axios"
import { setLogInterceptor } from "./interceptors"
import { URL_COMMON, URL_V1 } from "@/constants/api"
import { useMemoize } from "@vueuse/core"

/**
 *
 * @param {import("axios").AxiosRequestConfig} config
 * @returns
 */
function getInstance(config) {
  const instance = axios.create(config)

  setLogInterceptor(instance)

  return instance
}

const apiV1 = getInstance({
  baseURL: "/api",
})

/**
 *
 * READ: data 가 undefined이면 서버에서 에러나서 기본 {} 넣어줌
 * @param {string} url
 * @returns {(data:any, config: import("axios").AxiosRequestConfig) => Promise<import("axios").AxiosResponse>}
 */
const basePostCall =
  (url) =>
  (data = {}, config) =>
    apiV1.post(url, data, config)

export const api = {
  main: {
    selectMainNotice: basePostCall(URL_V1.main.selectMainNotice),
  },
  auth: {
    checkIpCidr: basePostCall(URL_COMMON.auth.checkIpCidr),
    createPopupCertPhone: basePostCall(URL_COMMON.auth.createPopupCertPhone),
    callbackPhone: basePostCall(URL_COMMON.auth.callbackPhone),
    callbackKakao: basePostCall(URL_COMMON.auth.callbackKakao),
    callbackNaver: basePostCall(URL_COMMON.auth.callbackNaver),
  },
  user: {
    isUserDuplicate: basePostCall(URL_V1.user.isUserDuplicate),
    scoQuery: basePostCall(URL_V1.user.scoQuery),
    findUserId: basePostCall(URL_V1.user.findUserId),
    resetPw: basePostCall(URL_V1.user.resetPw),
    resetPassword: basePostCall(URL_V1.user.resetPassword),
    joinTeacher: basePostCall(URL_V1.user.joinTeacher),
    firstLogin: basePostCall(URL_V1.user.firstLogin),
    setPassword: basePostCall(URL_V1.user.setPassword),
    agreePrivacy: basePostCall(URL_V1.user.agreePrivacy),
  },
  student: {
    login: basePostCall(URL_V1.student.login),
    findUserId: basePostCall(URL_V1.student.findUserId),
  },
  teacher: {
    login: basePostCall(URL_V1.teacher.login),
    findUserId: basePostCall(URL_V1.teacher.findUserId),
  },
  login: {
    checkTeacher: basePostCall(URL_V1.login.checkTeacher),
    login: basePostCall(URL_V1.login.login),
    logout: basePostCall(URL_V1.login.logout),
    userInfo: basePostCall(URL_V1.login.userInfo),
    isLoginUserVerify: basePostCall(URL_V1.login.isLoginUserVerify)
  },
  StudSchoReport: {
    upload: basePostCall(URL_V1.StudSchoReport.upload),
    selectStudSchoReportList: basePostCall(
      URL_V1.StudSchoReport.selectStudSchoReportList,
    ),
    selectStudSchoReportDetailList: basePostCall(
      URL_V1.StudSchoReport.selectStudSchoReportDetailList,
    ),
    selectStudSchoReportDetailListFromGrade: basePostCall(
      URL_V1.StudSchoReport.selectStudSchoReportDetailListFromGrade,
    ),
    getStudSchoGradeInfo: basePostCall(
      URL_V1.StudSchoReport.getStudSchoGradeInfo,
    ),
    getStudSchoClssRmInfo: basePostCall(
      URL_V1.StudSchoReport.getStudSchoClssRmInfo,
    ),
    getStudSchoGradeInfoFromGrade: basePostCall(
      URL_V1.StudSchoReport.getStudSchoGradeInfoFromGrade,
    ),
    getStudSchoClssRmInfoFromGrade: basePostCall(
      URL_V1.StudSchoReport.getStudSchoClssRmInfoFromGrade,
    ),
    updateStudSchoReportIntltsh: basePostCall(
      URL_V1.StudSchoReport.updateStudSchoReportIntltsh,
    ),
    getStudSchoHeadInfoList: basePostCall(
      URL_V1.StudSchoReport.getStudSchoHeadInfoList,
    ),
    getStudSchoHeadDetailInfoList: basePostCall(
      URL_V1.StudSchoReport.getStudSchoHeadDetailInfoList,
    ),
    uploadItem: basePostCall(URL_V1.StudSchoReport.uploadItem),
    calc: basePostCall(URL_V1.StudSchoReport.calc),
    getStdSaveData: basePostCall(URL_V1.StudSchoReport.getStdSaveData),
    selectStdNo: basePostCall(URL_V1.StudSchoReport.selectStdNo),
    saveGradeCheck: basePostCall(URL_V1.StudSchoReport.saveGradeCheck),
    delateGradeReport: basePostCall(URL_V1.StudSchoReport.delateGradeReport),
    getStudUnivFinalCnvrScore: basePostCall(
      URL_V1.StudSchoReport.getStudUnivFinalCnvrScore,
    ),
    getStudUnivDeptInfo: basePostCall(
      URL_V1.StudSchoReport.getStudUnivDeptInfo,
    ),
    selectStudSchoReportListFromGrade: basePostCall(
      URL_V1.StudSchoReport.selectStudSchoReportListFromGrade,
    ),
    getStudNo: basePostCall(URL_V1.StudSchoReport.getStudNo),
  },
  RegularStudSchoReport: {
    upload: basePostCall(URL_V1.RegularStudSchoReport.upload),
    getDetailRegularStudSchoScore: basePostCall(
      URL_V1.RegularStudSchoReport.getDetailRegularStudSchoScore,
    ),
    calc: basePostCall(URL_V1.RegularStudSchoReport.calc),
    uploadItem: basePostCall(URL_V1.RegularStudSchoReport.uploadItem),
    saveStudScore: basePostCall(URL_V1.RegularStudSchoReport.saveStudScore),
    doGradeScore: basePostCall(URL_V1.RegularStudSchoReport.doGradeScore),
    getUnivRefScoreInfo: basePostCall(
      URL_V1.RegularStudSchoReport.getUnivRefScoreInfo,
    ),
    updateRegularStudScore: basePostCall(
      URL_V1.RegularStudSchoReport.updateRegularStudScore,
    ),
    getConvertRegularInfo: basePostCall(
      URL_V1.RegularStudSchoReport.getConvertRegularInfo,
    ),
    getUnivAlarm: basePostCall(URL_V1.RegularStudSchoReport.getUnivAlarm),
    loadRegularStudScore: basePostCall(
      URL_V1.RegularStudSchoReport.loadRegularStudScore,
    ),
    loadRegularStudScoreCount: basePostCall(
      URL_V1.RegularStudSchoReport.loadRegularStudScoreCount,
    ),
    getRecruitTypeCount: basePostCall(URL_V1.RegularStudSchoReport.getRecruitTypeCount)
  },
  CourseDesign: {
    departlist: basePostCall(URL_V1.CourseDesign.departlist),
    majorlist: basePostCall(URL_V1.CourseDesign.majorlist)
  },
  admission: {
    aiSimulator: basePostCall(URL_V1.admission.aiSimulator),
    aiSimulatorUser: basePostCall(URL_V1.admission.aiSimulatorUser),
    selectUnivDept: basePostCall(URL_V1.admission.selectUnivDept),
    selectSimulatorHistory: basePostCall(
      URL_V1.admission.selectSimulatorHistory,
    ),
  },
  StudUnviReport: {
    calc: basePostCall(URL_V1.StudUnviReport.calc),
    calcFromGrade: basePostCall(URL_V1.StudUnviReport.calcFromGrade),
    selectStudUnviReportList: basePostCall(
      URL_V1.StudUnviReport.selectStudUnviReportList,
    ),
    selectStudUnviReportListFromGrade: basePostCall(
      URL_V1.StudUnviReport.selectStudUnviReportListFromGrade,
    ),
    selectStudUnviReportDetailList: basePostCall(
      URL_V1.StudUnviReport.selectStudUnviReportDetailList,
    ),
    selectStudUnviReportStudNoList: basePostCall(
      URL_V1.StudUnviReport.selectStudUnviReportStudNoList,
    ),
    getStudUnivFinalCnvrScore: basePostCall(
      URL_V1.StudUnviReport.getStudUnivFinalCnvrScore,
    ),
    getStudUnivFinalCnvrScoreList: basePostCall(
      URL_V1.StudUnviReport.getStudUnivFinalCnvrScoreList,
    ),
    getStudUnivDeptInfo: basePostCall(
      URL_V1.StudUnviReport.getStudUnivDeptInfo,
    ),
    getStudUnivDeptInfoFromGrade: basePostCall(
      URL_V1.StudUnviReport.getStudUnivDeptInfoFromGrade,
    ),
    selectStudUnviReportYearList: basePostCall(
      URL_V1.StudUnviReport.selectStudUnviReportYearList,
    ),
  },
  test: {
    aiReport: {
      sortQuery: basePostCall(URL_V1.test.aiReport.sortQuery),
      presentedInterview: basePostCall(URL_V1.test.aiReport.presentedInterview),
      paperInterview: basePostCall(URL_V1.test.aiReport.paperInterview),
      aiPaperInterview: basePostCall(URL_V1.test.aiReport.aiPaperInterview),
      presentedInterviewExtra: basePostCall(
        URL_V1.test.aiReport.presentedInterviewExtra,
      ),
      paperInterviewDepartment: basePostCall(
        URL_V1.test.aiReport.paperInterviewDepartment,
      ),
      paperInterviewBook: basePostCall(URL_V1.test.aiReport.paperInterviewBook),
      paperInterviewVolunteer: basePostCall(
        URL_V1.test.aiReport.paperInterviewVolunteer,
      ),
      paperInterviewAward: basePostCall(
        URL_V1.test.aiReport.paperInterviewAward,
      ),
      paperInterviewActivities: basePostCall(
        URL_V1.test.aiReport.paperInterviewActivities,
      ),
      receiveReport: basePostCall(URL_V1.test.aiReport.receiveReport),
      modelAnswer: basePostCall(URL_V1.test.aiReport.modelAnswer),
      scoringCriteria: basePostCall(URL_V1.test.aiReport.scoringCriteria),
      sendFile: basePostCall(URL_V1.test.aiReport.sendFile),
      uploadAnswerVideo: basePostCall(URL_V1.test.aiReport.uploadAnswerVideo),
      totalScore: basePostCall(URL_V1.test.aiReport.totalScore),
      reportHistory: basePostCall(URL_V1.test.aiReport.reportHistory),
      normalInterview: basePostCall(URL_V1.test.aiReport.normalInterview),
      tts: basePostCall(URL_V1.test.aiReport.tts),
      saveAiReportUserData: basePostCall(
        URL_V1.test.aiReport.saveAiReportUserData,
      ),
      saveAiReportHistory: basePostCall(
        URL_V1.test.aiReport.saveAiReportHistory,
      ),
      selectAiReportData: basePostCall(URL_V1.test.aiReport.selectAiReportData),
      selectRecentlyAiReportHistoryList: basePostCall(
        URL_V1.test.aiReport.selectRecentlyAiReportHistoryList,
      ),
      isCompleteAiReport: basePostCall(URL_V1.test.aiReport.isCompleteAiReport),
    },
  },
  menu: { selectMenuList: basePostCall(URL_V1.menu.selectMenuList) },
  common: {
    selectCommonCodeList: basePostCall(URL_V1.common.selectCommonCodeList),
    uploadBoardContentsImage: basePostCall(
      URL_V1.common.uploadBoardContentsImage,
    ),
  },
  search: {
    selectRecommendKeywordList: basePostCall(
      URL_V1.search.selectRecommendKeywordList,
    ),
  },
  QNABoard: {
    selectQNABoardContentsList: basePostCall(
      URL_V1.QNABoard.selectQNABoardContentsList,
    ),
    selectQNABoardContentsDetail: basePostCall(
      URL_V1.QNABoard.selectQNABoardContentsDetail,
    ),
  },
  event: {
    selectMainEventList: basePostCall(URL_V1.event.selectMainEventList),
  },
  myFutureCompany: {
    selectMyFutureCompanyList: basePostCall(
      URL_V1.myFutureCompany.selectMyFutureCompanyList,
    ),
  },
  myRealCareer: {
    selectMyRealCareerList: basePostCall(
      URL_V1.myRealCareer.selectMyRealCareerList,
    ),
  },
  mediaBoard: {
    selectMediaBoardContentsList: basePostCall(
      URL_V1.mediaBoard.selectMediaBoardContentsList,
    ),
  },
  jinhakInfo: {
    selectSusiNotiTable: basePostCall(URL_V1.jinhakInfo.selectSusiNotiTable),
    selectSusiNotiTableDetail: basePostCall(
      URL_V1.jinhakInfo.selectSusiNotiTableDetail,
    ),
    selectConditionUniv: useMemoize(
      basePostCall(URL_V1.jinhakInfo.selectConditionUniv),
    ),
    selectUnivDetail: basePostCall(URL_V1.jinhakInfo.selectUnivDetail),
    sortQuery: basePostCall(URL_V1.jinhakInfo.sortQuery),
    selectConditionDept: useMemoize(
      basePostCall(URL_V1.jinhakInfo.selectConditionDept),
    ),
    selectConditionDeptDetail: basePostCall(
      URL_V1.jinhakInfo.selectConditionDeptDetail,
    ),
    selectInterviewAnalysis: useMemoize(
      basePostCall(URL_V1.jinhakInfo.selectInterviewAnalysis),
    ),
    selectInterviewAnalysisDetail: basePostCall(
      URL_V1.jinhakInfo.selectInterviewAnalysisDetail,
    ),
    selectSusiSuccessAnalysis: useMemoize(
      basePostCall(URL_V1.jinhakInfo.selectSusiSuccessAnalysis),
    ),
    selectSusiSuccessAnalysisDetail: basePostCall(
      URL_V1.jinhakInfo.selectSusiSuccessAnalysisDetail,
    ),
    selectConditionDeptIntro: basePostCall(
      URL_V1.jinhakInfo.selectConditionDeptIntro,
    ),
    selectConditionDeptAsso: basePostCall(
      URL_V1.jinhakInfo.selectConditionDeptAsso,
    ),
    selectConditionDeptFuture: basePostCall(
      URL_V1.jinhakInfo.selectConditionDeptFuture,
    ),
    deptLikeList: basePostCall(URL_V1.jinhakInfo.deptLikeList),
    deptLike: basePostCall(URL_V1.jinhakInfo.deptLike),
    univLikeList: basePostCall(URL_V1.jinhakInfo.univLikeList),
    univLike: basePostCall(URL_V1.jinhakInfo.univLike),
  },
  normalBoard: {
    selectNormalBoardContentsList: basePostCall(
      URL_V1.normalBoard.selectNormalBoardContentsList,
    ),
    selectNormalBoardContentsDetail: basePostCall(
      URL_V1.normalBoard.selectNormalBoardContentsDetail,
    ),
    insertNormalBoardContents: basePostCall(
      URL_V1.normalBoard.insertNormalBoardContents,
    ),
    updateNormalBoardContents: basePostCall(
      URL_V1.normalBoard.updateNormalBoardContents,
    ),
    deleteNormalBoardContents: basePostCall(
      URL_V1.normalBoard.deleteNormalBoardContents,
    ),
  },
  boardComment: {
    selectBoardCommentList: basePostCall(
      URL_V1.normalBoard.selectBoardCommentList,
    ),
  },
  boardFile: {
    downloadFile: basePostCall(URL_V1.boardFile.downloadFile),
    downloadFileZip: basePostCall(URL_V1.boardFile.downloadFileZip),
  },
  myInfo: {
    selectMyInfo: basePostCall(URL_V1.myInfo.selectMyInfo),
    updateMyInfo: basePostCall(URL_V1.myInfo.updateMyInfo),

    updateMyInfoPassword: basePostCall(URL_V1.myInfo.updateMyInfoPassword),
    selectLikeDeptList: basePostCall(URL_V1.myInfo.selectLikeDeptList),
    selectLikeUnivList: basePostCall(URL_V1.myInfo.selectLikeUnivList),
    deleteMyInfo: basePostCall(URL_V1.myInfo.deleteMyInfo),
    uploadSimulatorPDF: basePostCall(URL_V1.myInfo.uploadSimulatorPDF),
    selectAiSimulatorHistory: basePostCall(
      URL_V1.myInfo.selectAiSimulatorHistory,
    ),
    sendAiSimulatorEmail: basePostCall(URL_V1.myInfo.sendAiSimulatorEmail),
    selectAiReportHistory: basePostCall(URL_V1.myInfo.selectAiReportHistory),
  },
  school: {
    upload: basePostCall(URL_V1.school.upload),
    volunUpload: basePostCall(URL_V1.school.volunUpload),
    creativeUpload: basePostCall(URL_V1.school.creativeUpload),
    behaviorUpload: basePostCall(URL_V1.school.behaviorUpload),
    specialUpload: basePostCall(URL_V1.school.specialUpload),
  },
  interview: {
    reserveStudent: basePostCall(URL_V1.interview.reserveStudent),
    reservationTest: basePostCall(URL_V1.interview.reservationTest)
  },
  survey: {
    isPossibleSatisfactionSurvey: basePostCall(URL_V1.survey.isPossibleSatisfactionSurvey),
    saveSatisfaction: basePostCall(URL_V1.survey.saveSatisfaction)
  }
}
