import {createApp, defineComponent, h} from "vue"
import App from "./App.vue"
import { router } from "./router"
import { createPinia } from "pinia"
import { useUserStore } from "@/store"
import piniaPluginPersistedstate from "pinia-plugin-persistedstate"
import mitt from "mitt"
import { api } from "./api"

import NewWindowApp from "./NewWindowApp.vue"
import WordCloud from "@/components/WordCloud.vue"

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

export const eventBus = mitt()

// 조건에 따라 렌더링할 컴포넌트를 선택
const RootComponent = defineComponent({
  render() {
    if(window.location.pathname.includes('/newWindow')){
      return h(NewWindowApp)
    }
    return h(App);
  },
});

createApp(RootComponent).use(pinia).use(router).mount("#app");

//중복로그인방지
document.onclick = async function leftClick() {
  const target = window.event.srcElement;
  const { accessToken, logout } = useUserStore()
  if (target) {
    if(accessToken){
      try {
        const result = await api.login.isLoginUserVerify()
        if(!result.data.isLogin){
          alert("다른 장소에서 로그인을 시도하여 로그아웃 되었습니다.")
          logout()
        }
      } catch(error) {
        console.log(error)
      }
    }
  }
};


document.addEventListener('DOMContentLoaded', (event) => {
  if(import.meta.env.VITE_ENV === 'production'){
    // 마우스 우클릭 방지
    document.body.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    }, false);
    // 마우스 드래그 방지
    document.body.addEventListener('selectstart', (e) => {
      e.preventDefault();
    }, false);
  }
});