import { computed } from "@vue/reactivity"
import dayjs from "dayjs"
import { defineStore } from "pinia"
import { ref } from "vue"
import { api } from "@/api"

export const useInterviewStore = defineStore(
  "interview",
  () => {
    const key = ref(null)
    const date = ref(dayjs().format("YYYYMMDD"))
    const type = ref()
    const statement = ref({})
    const questionItems = ref([])
    const records = ref([])
    const completeAiReportResult = ref([])

    const univNm = ref("")
    const modelNm = ref("")
    const affiNm = ref("")

    let elapsedTime = 0 

    function saveStatementInfo(info) {
      univNm.value = info.univNm
      modelNm.value = info.modelNm
      affiNm.value = info.affiNm
    }

    const statementSrc = computed(
      () => `/imgs/interview/statement/${statement.value.imagepath}`,
    )

    function setKey(_key) {
      key.value = _key
    }
    function setDate(_date) {
      date.value = _date
    }

    function addRecord(recordBlob) {
      records.value.push(recordBlob)
    }

    function resetQuestion() {
      statement.value = {}
      questionItems.value = []
      univNm.value = ""
      modelNm.value = ""
      affiNm.value = ""
    }
    function resetResult() {
      key.value = null
      date.value = dayjs().format("YYYYMMDD")
      records.value.forEach((url) => {
        URL.revokeObjectURL(url)
      })
      records.value = []
    }

    //모의리포트 결과성공 함수
    async function fetchCompleteAiReport(group_key) {
      try {
        const checkInterval = 5000 // 5초마다 호출
        const totalDuration = 900000 // 총 15분

        const timer = setInterval(async () => {
          const result = await api.test.aiReport.isCompleteAiReport({
            group_key,
          })
          completeAiReportResult.value = result.data.data.status

          elapsedTime += checkInterval

          if (
            completeAiReportResult.value === "S" || 
            completeAiReportResult.value === "F" || 
            elapsedTime >= totalDuration
          ) {
            localStorage.removeItem("savedParams")
            localStorage.removeItem("groupKey")
            clearInterval(timer) // s가 반환되거나 15분이 지나면 타이머 종료
          } 
        }, checkInterval)
      } catch (error) {
        alert(error.response?.data?.message || error.message)
      }
    }

    return {
      key,
      date,
      type,
      statement,
      questionItems,
      records,
      statementSrc,
      univNm,
      modelNm,
      affiNm,
      setKey,
      setDate,
      addRecord,
      resetQuestion,
      resetResult,
      saveStatementInfo,
      fetchCompleteAiReport,
      completeAiReportResult,
    }
  },
  {
    persist: {
      storage: sessionStorage,
    },
  },
)
