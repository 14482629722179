import { defineStore } from "pinia"
import { ref } from "vue"

export const useConsultingStore = defineStore(
  "consulting",
  () => {
    const isExcel = ref(false)
    const year = ref("2024")
    const studentItems = ref([])

    const oriUnivItems = ref([])
    const form = ref()

    return { isExcel, studentItems, oriUnivItems, year, form }
  },
  {
    persist: {
      storage: sessionStorage,
    },
  },
)
