import { ROUTES } from "@/constants/routes"
import { adminMiddleware, loginMiddleware } from "./_middleware"

export const boardRoutes = [
  {
    path: "/board/fileroom/detail",
    name: ROUTES.BOARD.FILEROOM_DETAIL,
    component: () => import("@/views/board/fileroom/Detail.vue"),
  },
  {
    path: "/board/fileroom/list",
    name: ROUTES.BOARD.FILEROOM_LIST,
    component: () => import("@/views/board/fileroom/List.vue"),
  },
  {
    path: "/board/fileroom/write",
    name: ROUTES.BOARD.FILEROOM_WRITE,
    component: () => import("@/views/board/fileroom/Write.vue"),
    beforeEnter: [loginMiddleware],
  },

  {
    path: "/board/notice/detail",
    name: ROUTES.BOARD.NOTICE_DETAIL,
    component: () => import("@/views/board/notice/Detail.vue"),
  },
  {
    path: "/board/notice/list",
    name: ROUTES.BOARD.NOTICE_LIST,
    component: () => import("@/views/board/notice/List.vue"),
  },
  {
    path: "/board/notice/write",
    name: ROUTES.BOARD.NOTICE_WRITE,
    component: () => import("@/views/board/notice/Write.vue"),
    beforeEnter: [loginMiddleware, adminMiddleware],
  },
  {
    path: "/board/notice/edit",
    name: ROUTES.BOARD.NOTICE_EDIT,
    component: () => import("@/views/board/notice/Edit.vue"),
    beforeEnter: [loginMiddleware, adminMiddleware],
  },
  {
    path: "/board/faq/detail",
    name: ROUTES.BOARD.FAQ_DETAIL,
    component: () => import("@/views/board/faq/Detail.vue"),
  },
  {
    path: "/board/faq/list",
    name: ROUTES.BOARD.FAQ_LIST,
    component: () => import("@/views/board/faq/List.vue"),
  },
  {
    path: "/board/faq/write",
    name: ROUTES.BOARD.FAQ_WRITE,
    component: () => import("@/views/board/faq/Write.vue"),
    beforeEnter: [loginMiddleware],
  },
]
