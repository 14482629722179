import { ROUTES } from "@/constants/routes"
import { delay } from "@/utils/util"
import {
  ipMiddleware,
  loginMiddleware,
  studentMiddleware,
  teacherMiddleware,
  teacherJustMiddleware,
  studentJustMiddleware,
  allMiddleware,
} from "./_middleware"

async function susiMiddleware(to, from, next) {
  if (import.meta.env.VITE_ENV !== "production") return next()

  await delay(200)
  alert("서비스 제공 기간이 아닙니다.")
  from.name ? next(false) : next({ name: ROUTES.MAIN.INDEX })
}

async function susiStudentMiddleware(to, from, next) {
  if (import.meta.env.VITE_ENV !== "production") return next()

  await delay(200)
  alert("서비스 제공 기간이 아닙니다.")
  from.name ? next(false) : next({ name: ROUTES.MAIN.INDEX })
}

export const entranceRoutes = [
  {
    path: "/entrance/consultingProgram",
    name: ROUTES.CONSULT.INDEX,
    component: () => import("@/views/entrance/consultingProgram/Index.vue"),
    beforeEnter: [ipMiddleware, loginMiddleware, teacherJustMiddleware],
  },
  {
    path: "/entrance/consultingProgram/grade",
    name: ROUTES.CONSULT.GRADE,
    component: () => import("@/views/entrance/consultingProgram/Grade.vue"),
    beforeEnter: [ipMiddleware, loginMiddleware, teacherJustMiddleware],
  },
  {
    path: "/entrance/consultingProgram/university",
    name: ROUTES.CONSULT.UNIVERSITY,
    component: () =>
      import("@/views/entrance/consultingProgram/University.vue"),
    beforeEnter: [ipMiddleware, loginMiddleware, teacherJustMiddleware],
  },

  {
    path: "/entrance/regularConsultingProgram",
    name: ROUTES.REGULAR_CONSULT.INDEX,
    component: () =>
      import("@/views/entrance/regularConsultingProgram/Index.vue"),
    beforeEnter: [loginMiddleware, ipMiddleware],
  },
  {
    path: "/entrance/regularConsultingProgram/grade",
    name: ROUTES.REGULAR_CONSULT.GRADE,
    component: () =>
      import("@/views/entrance/regularConsultingProgram/Grade.vue"),
    beforeEnter: [loginMiddleware, ipMiddleware],
  },

  {
    path: "/entrance/simulator",
    name: ROUTES.SIMULATOR.INDEX,
    component: () => import("@/views/entrance/simulator/Index.vue"),
    beforeEnter: [ipMiddleware, loginMiddleware],
  },
  {
    path: "/entrance/simulator/report",
    name: ROUTES.SIMULATOR.REPORT,
    component: () => import("@/views/entrance/simulator/Report.vue"),
    beforeEnter: [ipMiddleware, loginMiddleware],
  },
  {
    path: "/entrance/studentConsultingProgram",
    name: ROUTES.STUDENT_CONSULT.INDEX,
    component: () =>
      import("@/views/entrance/studentConsultingProgram/Index.vue"),
    beforeEnter: [ipMiddleware, loginMiddleware, studentJustMiddleware],
  },
  {
    path: "/entrance/studentConsultingProgram/grade",
    name: ROUTES.STUDENT_CONSULT.GRADE,
    component: () =>
      import("@/views/entrance/studentConsultingProgram/Grade.vue"),
    beforeEnter: [ipMiddleware, loginMiddleware, studentJustMiddleware],
  },
  {
    path: "/entrance/studentConsultingProgram/university",
    name: ROUTES.STUDENT_CONSULT.UNIVERSITY,
    component: () =>
      import("@/views/entrance/studentConsultingProgram/University.vue"),
    beforeEnter: [ipMiddleware, loginMiddleware, studentJustMiddleware],
  },
  {
    path: "/entrance/roadmapConsultingProgram/collegeConsulting/notice",
    component: () =>
      import(
        "@/views/entrance/roadmapConsultingProgram/collegeConsulting/index.vue"
      ),
    beforeEnter: [ipMiddleware, loginMiddleware],
  },
  {
    path: "/entrance/roadmapConsultingProgram/collegeConsulting/screeningSchedule",
    component: () =>
      import(
        "@/views/entrance/roadmapConsultingProgram/collegeConsulting/screeningSchedule.vue"
      ),
    beforeEnter: [ipMiddleware, loginMiddleware],
  },
  {
    path: "/entrance/roadmapConsultingProgram/collegeConsulting/screeningResult",
    component: () =>
      import(
        "@/views/entrance/roadmapConsultingProgram/collegeConsulting/screeningResult.vue"
      ),
    beforeEnter: [ipMiddleware],
  },
  {
    path: "/entrance/roadmapConsultingProgram/collegeConsulting/screeningFactor",
    component: () =>
      import(
        "@/views/entrance/roadmapConsultingProgram/collegeConsulting/screeningFactor.vue"
      ),
    beforeEnter: [ipMiddleware],
  },
  {
    path: "/entrance/roadmapConsultingProgram/regularAdmissionConsulting",
    component: () =>
      import(
        "@/views/entrance/roadmapConsultingProgram/regularAdmissionConsulting/index.vue"
      ),
    beforeEnter: [ipMiddleware],
  },
  {
    path: "/entrance/roadmapConsultingProgram/collegeConsulting",
    component: () =>
      import(
        "@/views/entrance/roadmapConsultingProgram/collegeConsulting/index.vue"
      ),
    beforeEnter: [ipMiddleware],
  },
  {
    //2024대학별 전형 방법 - 부산지역 전형방법
    path: "/entrance/roadmapConsultingProgram/univMethod/Method",
    name: ROUTES.ROADMAP_CONSULT.UNIV_METHOD_INDEX,
    component: () =>
      import("@/views/entrance/roadmapConsultingProgram/univMethod/Method.vue"),
  },
  {
    //2024 대학별 전형 방법 - 수시 원서마감 일정
    path: "/entrance/roadmapConsultingProgram/univMethod/Schedule",
    name: ROUTES.ROADMAP_CONSULT.UNIV_MEHTOD_SCHEDULE,
    component: () =>
      import(
        "@/views/entrance/roadmapConsultingProgram/univMethod/Schedule.vue"
      ),
  },
  {
    //대입길마중 수시 상담프로그램 - 모집단위 변경
    path: "/entrance/roadmapConsultingProgram/rollingAdmissionConsulting/Recruitment",
    name: ROUTES.ROADMAP_CONSULT.SUSI_CONSULTING_RECRUITMENT,
    component: () =>
      import(
        "@/views/entrance/roadmapConsultingProgram/rollingAdmissionConsulting/Recruitment.vue"
      ),
  },
  {
    //대입길마중 수시 상담프로그램 - 복수지원 가능 범위
    path: "/entrance/roadmapConsultingProgram/rollingAdmissionConsulting/MultipleSupport",
    name: ROUTES.ROADMAP_CONSULT.SUSI_CONSULTING_MULTIPLESUPPORT,
    component: () =>
      import(
        "@/views/entrance/roadmapConsultingProgram/rollingAdmissionConsulting/MultipleSupport.vue"
      ),
  },
  {
    //대입길마중 수시 상담프로그램 - 대학별 고사 일정
    path: "/entrance/roadmapConsultingProgram/rollingAdmissionConsulting/CollegeSchedule",
    name: ROUTES.ROADMAP_CONSULT.SUSI_CONSULTING_COLLEGESCHEDULE,
    component: () =>
      import(
        "@/views/entrance/roadmapConsultingProgram/rollingAdmissionConsulting/CollegeSchedule.vue"
      ),
  },
  {
    //대입길마중 수시 상담프로그램 - 대학별 수능최저학력기준 요약
    path: "/entrance/roadmapConsultingProgram/rollingAdmissionConsulting/AdigaScore",
    name: ROUTES.ROADMAP_CONSULT.SUSI_CONSULTING_ADIGASCORE,
    component: () =>
      import(
        "@/views/entrance/roadmapConsultingProgram/rollingAdmissionConsulting/AdigaScore.vue"
      ),
  },
  {
    //대입길마중 전문대 상담프로그램 - 전형 일정
    path: "/entrance/roadmapConsultingProgram/collegeConsulting/AdmissionsTimeline",
    name: ROUTES.ROADMAP_CONSULT.JUNGSI_CONSULTING_ADMISSIONSTIMELINE,
    component: () =>
      import(
        "@/views/entrance/roadmapConsultingProgram/collegeConsulting/AdmissionsTimeline.vue"
      ),
  },
]
