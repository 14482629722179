import { reactive } from "vue"
import { noop } from "./util"

const signup = reactive({
  neis: "",
  success: false,
  notAfter: "",
  subjectCn: "",
  name: "",
})

/**
 * url parameter 파싱
 */
function getQuery(search) {
  const result = {}

  if (typeof search !== "string") return result

  console.log("getQuery:search", search)

  if (search) {
    let qs = search.slice(1).split("&")
    for (let i = 0; i < qs.length; i++) {
      qs[i] = qs[i].split("=")
      result[qs[i][0]] = decodeURIComponent(qs[i][1])
    }
  }
  return result
}

/**
 * 윈도우 팝업창 중앙정렬 위치
 * @param {*} w
 * @param {*} h
 * @returns
 */
function windowPopupCenterOpen(w, h) {
  const dualScreenLeft =
    window.screenLeft != undefined ? window.screenLeft : screen.left
  const dualScreenTop =
    window.screenTop != undefined ? window.screenTop : screen.top
  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height
  const left = width / 2 - w / 2 + dualScreenLeft
  const top = height / 2 - h / 2 + dualScreenTop
  const options =
    "width=" +
    w +
    ",height=" +
    h +
    ",toolbar=no,menubar=no,location=no,status=no,scrollbars=no,resizable=no,fullscreen=no,left=" +
    left +
    ",top=" +
    top +
    ",directories=no"
  return { left: left, top: top, options: options }
}

let resolveFunc = noop

function onMessage(e) {
  console.log("onMessage", e, e.data)

  const search = e.data
  if (!search) return
  const result = getQuery(search)

  if (result.success === "T") {
    resolveFunc({
      notAfter: result.date,
      subjectCn: result.cn,
    })
    clearEpki()
  }
}

function clearEpki() {
  window.removeEventListener("message", onMessage)
}

/**
 * 교원 인증서
 */
export async function popupEpki({ isLogin = false } = {}) {
  signup.success = false
  window.addEventListener("message", onMessage, false)

  return new Promise((resolve) => {
    const w = window.outerWidth
    const h = 800
    const openValue = windowPopupCenterOpen(w, h)
    const options = openValue.options
    window.open(
      `/api/call/epki?isLogin=${isLogin ? "T" : "F"}`, // local: /api/call/epki real: /call/epki
      "교직원 인증창",
      options,
    )

    resolveFunc = resolve
  })
}
