import { api } from "@/api"
import { ROUTES } from "@/constants/routes"
import { useUserStore } from "@/store"
import { router } from "@/router"

export async function ipMiddleware(to, from, next) {
  const result = await api.auth.checkIpCidr()
  const canAccess = result.data === true

  if (canAccess) return next()

  alert(
    "서비스 가능한 위치가 아닙니다. 서비스가 가능한 곳에서 접속 부탁드립니다",
  )

  from.name ? next(false) : next({ name: ROUTES.MAIN.INDEX })
}

export function nonLoginMiddleware(to, from, next) {
  const userStore = useUserStore()

  if (!userStore.isLoggedIn) return next()

  from.name ? next(false) : next({ name: ROUTES.MAIN.INDEX })
}

export function loginMiddleware(to, from, next) {
  const userStore = useUserStore()

  if (userStore.isLoggedIn) return next()

  next({ name: ROUTES.LOGIN.INDEX, query: { next: to.fullPath } })
}

export function studentMiddleware(to, from, next) {
  const userStore = useUserStore()

  if (userStore.isAdmin) return next()
  if (userStore.isStudent) return next()

  from.name ? next(false) : next({ name: ROUTES.MAIN.INDEX })
}

export function parentMiddleware(to, from, next) {
  const userStore = useUserStore()

  if (userStore.isAdmin) return next()
  if (userStore.isParent) return next()

  from.name ? next(false) : next({ name: ROUTES.MAIN.INDEX })
}

export function teacherMiddleware(to, from, next) {
  const userStore = useUserStore()

  if (userStore.isAdmin) return next()
  if (userStore.isTeacher) return next()
  alert("교사만 신청 가능합니다.")

  from.name ? next(false) : next({ name: ROUTES.MAIN.INDEX })
}

export function adminMiddleware(to, from, next) {
  const userStore = useUserStore()

  if (userStore.isAdmin) return next()

  from.name ? next(false) : next({ name: ROUTES.MAIN.INDEX })
}

export function studentJustMiddleware(to, from, next) {
  const userStore = useUserStore()

  if (userStore.isStudent||userStore.isAdmin) return next()

  alert("학생계정만 사용가능한 페이지입니다.")

  from.name ? next(false) : next({ name: ROUTES.MAIN.INDEX })
}

export function teacherJustMiddleware(to, from, next) {
  const userStore = useUserStore()

  if (userStore.isTeacher||userStore.isAdmin) return next()

  alert("교사계정만 사용가능한 페이지입니다.")

  from.name ? next(false) : next({ name: ROUTES.MAIN.INDEX })
}

export function allMiddleware(to, from, next) {
  const userStore = useUserStore()
  // if (import.meta.env.VITE_ENV === "local") {
  //   if (userStore.isTeacher) return next()
  // }

  if (import.meta.env.VITE_ENV === "production") {
    if (
      userStore.isStudent ||
      userStore.isTeacher ||
      userStore.isAdmin ||
      userStore.isParent
    ) {
      alert("접근할 수 없는 페이지입니다.")
      from.name ? next(false) : next({ name: ROUTES.MAIN.INDEX })
    } else {
      return next()
    }
  } else {
    if(userStore.isLoggedIn) return next()
  }
  from.name ? next(false) : next({ name: ROUTES.MAIN.INDEX })
}

export async function reserveStudentMiddleware() { //학생 모의면접 참여 가능 여부
  const userAgent = navigator.userAgent.toLocaleLowerCase()

  if (userAgent.indexOf('whale') > -1) {
    alert('지원하지 않는 브라우저입니다.')
    router.push({ name: ROUTES.MAIN.INDEX })
  }else{
    try {
      await api.interview.reserveStudent()
    } catch (error) {
      alert(error.response.data.message);
      router.push("/main")
    }
  }
}