export const API_LOG_TYPE = {
  REQ: "REQ",
  RES: "RES",
  WARN: "WARN",
  ERROR: "ERROR",
}

export const URL_COMMON = {
  auth: {
    checkIpCidr: "/auth/checkIpCidr",
    createPopupCertPhone: "/auth/createPopupCertPhone",
    callbackPhone: "/auth/callbackPhone",
    callbackKakao: "/auth/callbackKakao",
    callbackNaver: "/auth/callbackNaver",
  },
}

export const URL_V1 = {
  main: {
    selectMainNotice: "/v1/main/selectMainNotice",
  },
  menu: {
    selectMainMenuTree: "/v1/menu/selectMainMenuTree",
    selectMenuList: "/v1/menu/selectMenuList"
  },
  user: {
    isUserDuplicate: "/v1/user/isUserDuplicate",
    scoQuery: "/v1/user/scoQuery",
    findUserId: "/v1/user/findUserId",
    resetPw: "/v1/user/resetPw",
    resetPassword: "/v1/user/find/password",
    joinTeacher: "/v1/user/joinUser",
    firstLogin: "/v1/user/firstLogin",
    setPassword: "/v1/user/reset/password",
    agreePrivacy: "/v1/user/update/agreePrivacy",
  },
  student: {
    login: "/v1/login/login/student",
    findUserId: "/v1/user/student/findUserId",
  },
  teacher: {
    login: "/v1/login/login/teacher",
    findUserId: "/v1/user/teacher/findUserId",
  },
  login: {
    checkTeacher: "/v1/login/checkTeacher",
    login: "/v1/login/login",
    logout: "/v1/login/logout",
    userInfo: "/v1/login/get/userInfo",
    isLoginUserVerify: "/v1/login/get/isLoginUserVerify"
  },
  StudSchoReport: {
    upload: "/v1/StudSchoReport/upload",
    selectStudSchoReportList: "/v1/StudSchoReport/selectStudSchoReportList",
    selectStudSchoReportDetailList:
      "/v1/StudSchoReport/selectStudSchoReportDetailList",
    selectStudSchoReportDetailListFromGrade:
      "/v1/StudSchoReport/selectStudSchoReportDetailListFromGrade",
    getStudSchoGradeInfo: "/v1/StudSchoReport/getStudSchoGradeInfo",
    getStudSchoClssRmInfo: "/v1/StudSchoReport/getStudSchoClssRmInfo",
    getStudSchoGradeInfoFromGrade:
      "/v1/StudSchoReport/getStudSchoGradeInfoFromGrade",
    getStudSchoClssRmInfoFromGrade:
      "/v1/StudSchoReport/getStudSchoClssRmInfoFromGrade",
    updateStudSchoReportIntltsh:
      "/v1/StudSchoReport/updateStudSchoReportIntltsh",
    getStudSchoHeadInfoList: "/v1/StudSchoReport/getStudSchoHeadInfoList",
    getStudSchoHeadDetailInfoList:
      "/v1/StudSchoReport/getStudSchoHeadDetailInfoList",
    uploadItem: "/v1/StudSchoReport/uploadItem",
    calc: "/v1/StudSchoReport/calc",
    getStdSaveData:
      "/v1/StudSchoReport/selectStudSchoReportDetailListFromGrade",
    selectStdNo: "/v1/StudSchoReport/studNo",
    saveGradeCheck: "/v1/StudSchoReport/insertStudSchoReport",
    delateGradeReport: "/v1/StudSchoReport/deleteStudSchoReport",
    getStudUnivFinalCnvrScore: "/v1/StudUnviReport/getStudUnivFinalCnvrScore",
    getStudUnivDeptInfo: "/v1/StudUnviReport/getStudUnivDeptInfo",
    selectStudSchoReportListFromGrade:
      "/v1/StudSchoReport/selectStudSchoReportListFromGrade",
    getStudNo: "/v1/StudSchoReport/studNo",
  },
  RegularStudSchoReport: {
    upload: "/v1/RegularStudSchoReport/upload",
    getDetailRegularStudSchoScore:
      "/v1/RegularStudSchoReport/getDetailRegularStudSchoScore",
    calc: "/v1/RegularStudSchoReport/calc",
    uploadItem: "/v1/RegularStudSchoReport/uploadItem",
    saveStudScore: "v1/RegularStudSchoReport/saveStudScore",
    doGradeScore: "/v1/RegularStudSchoReport/doGradeScore",
    getUnivRefScoreInfo: "/v1/RegularStudSchoReport/getUnivRefScoreInfo",
    updateRegularStudScore: "/v1/RegularStudSchoReport/updateRegularStudScore",
    getConvertRegularInfo: "/v1/RegularStudSchoReport/getConvertRegularInfo",
    getUnivAlarm: "/v1/RegularStudSchoReport/getUnivAlarm",
    loadRegularStudScore: "/v1/RegularStudSchoReport/loadRegularStudScore",
    loadRegularStudScoreCount:
      "/v1/RegularStudSchoReport/loadRegularStudScoreCount",
    getRecruitTypeCount: "/v1/RegularStudSchoReport/getRecruitTypeCount"
  },
  CourseDesign: {
    departlist: "v1/course-design/depart/list",
    majorlist: "v1/course-design/major/list"
  },
  admission: {
    aiSimulator: "/v1/admission/aiSimulator",
    aiSimulatorUser: "/v1/admission/saveAiSimulatorUserData",
    selectUnivDept: "/v1/admission/selectUnivDept",
    selectSimulatorHistory: "/v1/admission/selectSimulatorHistory",
  },
  StudUnviReport: {
    calc: "/v1/StudUnviReport/calc",
    calcFromGrade: "/v1/StudUnviReport/calcFromGrade",
    selectStudUnviReportList: "/v1/StudUnviReport/selectStudUnviReportList",
    selectStudUnviReportListFromGrade:
      "/v1/StudUnviReport/selectStudUnviReportListFromGrade",
    selectStudUnviReportDetailList:
      "/v1/StudUnviReport/selectStudUnviReportDetailList",
    selectStudUnviReportStudNoList:
      "/v1/StudUnviReport/selectStudUnviReportStudNoList",
    getStudUnivFinalCnvrScore: "/v1/StudUnviReport/getStudUnivFinalCnvrScore",
    getStudUnivFinalCnvrScoreList:
      "/v1/StudUnviReport/getStudUnivFinalCnvrScoreList",
    getStudUnivDeptInfo: "/v1/StudUnviReport/getStudUnivDeptInfo",
    getStudUnivDeptInfoFromGrade:
      "/v1/StudUnviReport/getStudUnivDeptInfoFromGrade",
    selectStudUnviReportYearList:
      "/v1/StudUnviReport/selectStudUnviReportYearList",
  },
  test: {
    aiReport: {
      sortQuery: "/v1/test/aiReport/sortQuery",
      presentedInterview: "/v1/test/aiReport/presentedInterview",
      paperInterview: "/v1/test/aiReport/paperInterview",
      aiPaperInterview: "/v1/test/aiReport/aiPaperInterview",
      presentedInterviewExtra: "/v1/test/aiReport/presentedInterview/extra",
      paperInterviewDepartment: "/v1/test/aiReport/paperInterview/department",
      paperInterviewBook: "/v1/test/aiReport/paperInterview/book",
      paperInterviewVolunteer: "/v1/test/aiReport/paperInterview/volunteer",
      paperInterviewAward: "/v1/test/aiReport/paperInterview/award",
      paperInterviewActivities: "/v1/test/aiReport/paperInterview/activities",
      receiveReport: "/v1/test/aiReport/receiveReport",
      modelAnswer: "/v1/test/aiReport/modelAnswer",
      scoringCriteria: "/v1/test/aiReport/scoringCriteria",
      sendFile: "/v1/test/aiReport/sendFile",
      uploadAnswerVideo: "/v1/test/aiReport/uploadAnswerVideo",
      totalScore: "/v1/test/aiReport/totalScore",
      reportHistory: "/v1/test/aiReport/reportHistory",
      normalInterview: "/v1/test/aiReport/normalInterview",
      saveAiReportUserData: "/v1/test/aiReport/saveAiReportUserData",
      saveAiReportHistory: "/v1/test/aiReport/saveAiReportHistory",
      selectAiReportData: "/v1/test/aiReport/selectAiReportData",
      selectRecentlyAiReportHistoryList:
        "/v1/test/aiReport/selectRecentlyAiReportHistoryList",
      isCompleteAiReport: "/v1/test/aiReport/isCompleteAiReport",
      tts: "/v1/test/aiReport/tts",
    },
  },
  common: {
    selectCommonCodeList: "/v1/common/selectCommonCodeList",
    uploadBoardContentsImage: "/v1/common/uploadBoardContentsImage",
  },
  search: {
    selectRecommendKeywordList: "/v1/search/selectRecommendKeywordList",
  },
  event: { selectMainEventList: "/v1/event/selectMainEventList" },
  myFutureCompany: {
    selectMyFutureCompanyList: "/v1/myFutureCompany/selectMyFutureCompanyList",
  },
  myRealCareer: {
    selectMyRealCareerList: "/v1/myRealCareer/selectMyRealCareerList",
  },
  mediaBoard: {
    selectMediaBoardContentsList: "/v1/mediaBoard/selectMediaBoardContentsList",
  },
  jinhakInfo: {
    selectSusiNotiTable: "/v1/jinhakInfo/selectSusiNotiTable",
    selectSusiNotiTableDetail: "/v1/jinhakInfo/selectSusiNotiTableDetail",
    selectConditionUniv: "/v1/jinhakInfo/selectConditionUniv",
    selectUnivDetail: "/v1/jinhakInfo/selectUnivDetail",
    sortQuery: "/v1/jinhakInfo/sortQuery",
    selectConditionDept: "/v1/jinhakInfo/selectConditionDept",
    selectConditionDeptDetail: "/v1/jinhakInfo/selectConditionDeptDetail",
    selectInterviewAnalysis: "/v1/jinhakInfo/selectInterviewAnalysis",
    selectInterviewAnalysisDetail:
      "/v1/jinhakInfo/selectInterviewAnalysisDetail",
    selectSusiSuccessAnalysis: "/v1/jinhakInfo/selectSusiSuccessAnalysis",
    selectSusiSuccessAnalysisDetail:
      "/v1/jinhakInfo/selectSusiSuccessAnalysisDetail",
    selectConditionDeptIntro: "/v1/jinhakInfo/selectConditionDeptIntro",
    selectConditionDeptAsso: "/v1/jinhakInfo/selectConditionDeptAsso",
    selectConditionDeptFuture: "/v1/jinhakInfo/selectConditionDeptFuture",
    deptLikeList: "/v1/jinhakInfo/deptLikeList",
    deptLike: "/v1/jinhakInfo/deptLike",
    univLikeList: "/v1/jinhakInfo/univLikeList",
    univLike: "/v1/jinhakInfo/univLike",
  },
  normalBoard: {
    selectNormalBoardContentsList:
      "/v1/normalBoard/selectNormalBoardContentsList",
    selectNormalBoardContentsDetail:
      "/v1/normalBoard/selectNormalBoardContentsDetail",
    insertNormalBoardContents: "/v1/normalBoard/insertNormalBoardContents",
    updateNormalBoardContents: "/v1/normalBoard/updateNormalBoardContents",
    deleteNormalBoardContents: "/v1/normalBoard/deleteNormalBoardContents",
  },
  QNABoard: {
    selectQNABoardContentsList: "/v1/QNABoard/selectQNABoardContentsList",
    selectQNABoardContentsDetail: "/v1/QNABoard/selectQNABoardContentsDetail",
  },
  boardComment: {
    selectBoardCommentList: "/v1/boardComment/selectBoardCommentList",
  },
  boardFile: {
    downloadFile: "/v1/boardFile/downloadFile",
    downloadFileZip: "/v1/boardFile/downloadFileZip",
  },
  myInfo: {
    selectMyInfo: "/v1/myInfo/selectMyInfo",
    updateMyInfo: "/v1/myInfo/updateMyInfo",

    updateMyInfoPassword: "/v1/myInfo/updateMyInfoPassword",
    selectLikeDeptList: "/v1/myInfo/selectLikeDeptList",
    selectLikeUnivList: "/v1/myInfo/selectLikeUnivList",
    deleteMyInfo: "/v1/myInfo/deleteMyInfo",
    passwordCheck: "/v1/myInfo/passwordCheck",
    uploadSimulatorPDF: "/v1/admission/uploadSimulatorPDF",
    selectAiSimulatorHistory: "/v1/myInfo/selectAiSimulatorHistory",
    sendAiSimulatorEmail: "/v1/myInfo/sendAiSimulatorEmail",
    selectAiReportHistory: "/v1/myInfo/selectAiReportHistory",
  },
  school: {
    upload: "/v1/school/student/grade/upload/schoReport",
    volunUpload: "/v1/school/student/grade/upload/volunteer",
    creativeUpload: "/v1/school/student/grade/upload/creative",
    behaviorUpload: "/v1/school/student/grade/upload/behavior",
    specialUpload: "/v1/school/student/grade/upload/special",
  },
  interview: {
    reserveStudent: '/v1/interview/reservation/student/isJoin',
    reservationTest: '/v1/interview/reservation/info'
  },
  survey: {
    isPossibleSatisfactionSurvey: '/v1/survey/isPossibleSatisfactionSurvey',
    saveSatisfaction: '/v1/survey/saveSatisfaction'
  }
}
