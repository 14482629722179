import { ROUTES } from "@/constants/routes"
import { 
  ipMiddleware, 
  loginMiddleware, 
  teacherMiddleware, 
  studentJustMiddleware,
  reserveStudentMiddleware,//학생 모의면접 참여 가능 여부
} from "./_middleware"

export const interviewRoutes = [
  {
    path: "/interview",
    name: ROUTES.INTERVIEW.INDEX,
    component: () => import("@/views/interview/Index.vue"),
    beforeEnter: [ipMiddleware, loginMiddleware],
  },
  {
    path: "/interview/interview",
    name: ROUTES.INTERVIEW.INTERVIEW,
    component: () => import("@/views/interview/Interview.vue"),
    meta: { layout: "PlainLayout" },
    beforeEnter: [ipMiddleware, loginMiddleware],
  },
  {
    path: "/interview/notice",
    name: ROUTES.INTERVIEW.NOTICE,
    component: () => import("@/views/interview/Notice.vue"),
    beforeEnter: [ipMiddleware, loginMiddleware],
  },
  {
    path: "/interview/test",
    name: ROUTES.INTERVIEW.TEST,
    component: () => import("@/views/interview/Test.vue"),
    beforeEnter: [ipMiddleware, loginMiddleware],
  },
  {
    path: "/interview/type",
    name: ROUTES.INTERVIEW.TYPE,
    component: () => import("@/views/interview/Type.vue"),
    beforeEnter: [ipMiddleware, loginMiddleware],
  },

  {
    path: "/interview/document",
    name: ROUTES.INTERVIEW.DOCUMENT_INDEX,
    component: () => import("@/views/interview/document/Index.vue"),
    beforeEnter: [ipMiddleware, loginMiddleware],
  },
  {
    path: "/interview/document/intro",
    name: ROUTES.INTERVIEW.DOCUMENT_INTRO,
    component: () => import("@/views/interview/document/Intro.vue"),
    beforeEnter: [ipMiddleware, loginMiddleware],
  },
  {
    path: "/interview/document/report",
    name: ROUTES.INTERVIEW.DOCUMENT_REPORT,
    component: () => import("@/views/interview/document/Report.vue"),
    beforeEnter: [ipMiddleware, loginMiddleware],
  },

  {
    path: "/interview/statement",
    name: ROUTES.INTERVIEW.STATEMENT_INDEX,
    component: () => import("@/views/interview/statement/Index.vue"),
    beforeEnter: [ipMiddleware, loginMiddleware],
  },
  {
    path: "/interview/statement/report",
    name: ROUTES.INTERVIEW.STATEMENT_REPORT,
    component: () => import("@/views/interview/statement/Report.vue"),
    beforeEnter: [ipMiddleware, loginMiddleware],
  },
  {
    path: "/interview/interviewer",
    name: ROUTES.INTERVIEW.INTERVIEWER,
    component: () => import("@/views/interview/Interviewer.vue"),
    beforeEnter: [ipMiddleware, loginMiddleware],
  },
  {
    path: "/interview/normal/intro",
    name: ROUTES.INTERVIEW.NORMAL_INTRO,
    component: () => import("@/views/interview/normal/Intro.vue"),
    beforeEnter: [ipMiddleware, loginMiddleware],
  },
  {
    path: "/interview/statement/intro",
    name: ROUTES.INTERVIEW.STATEMENT_INTRO,
    component: () => import("@/views/interview/statement/Intro.vue"),
    beforeEnter: [ipMiddleware, loginMiddleware],
  },
  {
    path: "/interview/reservation",
    name: ROUTES.INTERVIEW.RESERVATION_INDEX,
    component: () => import("@/views/interview/reservation/Index.vue"),
    beforeEnter: [ipMiddleware, loginMiddleware, teacherMiddleware],
  },
  {
    path: "/interview/reservation/apply",
    name: ROUTES.INTERVIEW.RESERVATION_APPLY,
    component: () => import("@/views/interview/reservation/Apply.vue"),
    beforeEnter: [ipMiddleware, loginMiddleware, teacherMiddleware],
  },
  {
    path: "/interview/reservation/history",
    name: ROUTES.INTERVIEW.RESERVATION_HISTORY,
    component: () => import("@/views/interview/reservation/History.vue"),
    beforeEnter: [ipMiddleware, loginMiddleware, teacherMiddleware],
  },
  {
    path: "/interview/studentreservation",
    name: ROUTES.INTERVIEW.STUDENTRESERVATION,
    component: () => import("@/views/interview/reservation/Index.vue"),
    beforeEnter: [ipMiddleware, loginMiddleware, studentJustMiddleware, reserveStudentMiddleware],
  },
  {
    path: "/interview/studentreservation/notice",
    name: ROUTES.INTERVIEW.STUDENTRESERVATION_NOTICE,
    component: () => import("@/views/interview/Notice.vue"),
    beforeEnter: [ipMiddleware, loginMiddleware, studentJustMiddleware, reserveStudentMiddleware],
  },
  {
    path: "/interview/studentreservation/test",
    name: ROUTES.INTERVIEW.STUDENTRESERVATION_TEST,
    component: () => import("@/views/interview/Test.vue"),
    beforeEnter: [ipMiddleware, loginMiddleware, studentJustMiddleware, reserveStudentMiddleware],
  },
  {
    path: "/interview/studentreservation/intro",
    name: ROUTES.INTERVIEW.STUDENTRESERVATION_INTRO,
    component: () => import("@/views/interview/document/Intro.vue"),
    beforeEnter: [ipMiddleware, loginMiddleware, studentJustMiddleware, reserveStudentMiddleware],
  },
]
