function _getRandomColor() {
  return (
    "#" +
    Math.floor(Math.random() * 16777215)
      .toString(16)
      .padStart(6, "0")
  )
}

export function getRandomColor(minLuma = 0, maxLuma = 255) {
  let color, luma
  do {
    color = _getRandomColor()
    luma = getLuma(color)
  } while (minLuma > luma || luma > maxLuma)

  return color
}

export function getLuma(color) {
  // color can be a hx string or an array of RGB values 0-255
  var rgb = typeof color === "string" ? hexToRGBArray(color) : color
  return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2] // SMPTE C, Rec. 709 weightings
}

export function hexToRGBArray(color) {
  let _color = color.replace("#", "")

  if (_color.length === 3)
    _color =
      _color.charAt(0) +
      _color.charAt(0) +
      _color.charAt(1) +
      _color.charAt(1) +
      _color.charAt(2) +
      _color.charAt(2)
  else if (_color.length !== 6) throw "Invalid hex color: " + _color

  const rgb = []
  for (var i = 0; i <= 2; i++) rgb[i] = parseInt(_color.substr(i * 2, 2), 16)

  return rgb
}
