export const ROUTES = {
  /** 메인 */
  MAIN: {
    INDEX: "MAIN_INDEX",
    WAITING: "MAIN_WAITING",
  },
  /** 마이페이지 */
  MYPAGE: {
    EDIT: "MYPAGE_EDIT",
    MYINFO: "MYPAGE_MYINFO",
    INDEX: "MYPAGE_INDEX",
    INTEREST_LIST: "MYPAGE_INTEREST_LIST",
    INTERVIEW_HISTORY: "MYPAGE_INTERVIEW_HISTORY",
    PASSWORD_EDIT: "MYPAGE_PASSWORD_EDIT",
    PASSWORD_INPUT: "MYPAGE_PASSWORD_INPUT",
    QUIT: "MYPAGE_QUIT",
    REPORT_HISTORY: "MYPAGE_REPORT_HISTORY",
    TEACHER_MANAGE_STUDENT: "MYPAGE.TEACHER_MANAGE_STUDENT",
    TEACHER_MANAGE_TEACHER: "MYPAGE.TEACHER_MANAGE_TEACHER",
    TEACHER_REGISTER_CLASS: "MYPAGE.TEACHER_REGISTER_CLASS",
    TEACHER_REGISTER_STUDENT: "MYPAGE.TEACHER_REGISTER_STUDENT",
    TEACHER_REGISTER_TEACHER: "MYPAGE.TEACHER_REGISTER_TEACHER",
    TEACHER_CURRICULUM: "MYPAGE.TEACHER_CURRICULUM",
    TEACHER_CURRICULUM_DETAIL: "MYPAGE.TEACHER_CURRICULUM_DETAIL",
    TEACHER_EXTRACURRICULAR: "MYPAGE.TEACHER_EXTRACURRICULAR",
  },
  /** 로그인 */
  LOGIN: {
    /** 로그인 인덱스 */
    INDEX: "LOGIN_INDEX",
    /** 아이디 찾기 */
    FIND_ID: "LOGIN_FIND_ID",
    /** 비밀번호 찾기 */
    FIND_PW: "LOGIN_FIND_PW",
    /** 아이디 찾기(학생) */
    FIND_ID_STUDENT: "FIND_ID_STUDENT",
    /** 아이디 찾기(교사) */
    FIND_ID_TEACHER: "FIND_ID_TEACHER",
    /** 비밀번호 재설정(정보입력) */
    RESET_PASSWORD: "RESET_PASSWORD",
    /** 비밀번호 재설정 */
    SET_PASSWORD: "SET_PASSWORD",
    /** EPKI 로그인 */
    EPKI: "LOGIN_EPKI",
    /** 회원가입 인덱스 */
    JOIN_INDEX: "LOGIN_JOIN_INDEX",
    /** 회원가입 스텝 1 */
    JOIN_STEP01: "LOGIN_JOIN_STEP01",
    /** 회원가입 스텝 2 */
    JOIN_STEP02: "LOGIN_JOIN_STEP02",
    /** 회원가입 스텝 3 - 1 */
    JOIN_STEP03_1: "LOGIN_JOIN_STEP03_1",
    /** 회원가입 스텝 3 - 2 */
    JOIN_STEP03_2: "LOGIN_JOIN_STEP03_2",
    /** 회원가입 스텝 4 */
    JOIN_STEP04: "LOGIN_JOIN_STEP04",
    /** 회원가입 스텝 5 */
    JOIN_STEP05: "LOGIN_JOIN_STEP05",
    /** EPKI callback */
    CALLBACK_EPKI: "LOGIN_CALLBACK_EPKI",
    /** Phone callback */
    CALLBACK_PHONE: "LOGIN_CALLBACK_PHONE",
    /** KAKAO callback */
    CALLBACK_KAKAO: "LOGIN_CALLBACK_KAKAO",
    /** NAVER callback */
    CALLBACK_NAVER: "LOGIN_CALLBACK_NAVER",
    /** 관리교사 회원가입 스텝 1 */
    JOIN_TEACHER_STEP01: "JOIN_TEACHER_STEP01",
    /** 관리교사 회원가입 스텝 2 */
    JOIN_TEACHER_STEP02: "JOIN_TEACHER_STEP02",
    /** 관리교사 회원가입 스텝 3 */
    JOIN_TEACHER_STEP03: "JOIN_TEACHER_STEP03",
    /** 관리교사 회원가입 스텝 4 */
    JOIN_TEACHER_STEP04: "JOIN_TEACHER_STEP04",
  },
  /** 진학상담 */
  INFOMATION: {
    /** 수시 알람표 */
    SUMMARY_TABLE: "SUMMARY_TABLE",
    /** 정시 알람표 */
    REGULAR_SUMMARY_TABLE: "REGULAR_SUMMARY_TABLE",
    /** 학과정보 */
    DEPARTMENT_INDEX: "DEPARTMENT_INDEX",
    /** 학과정보 상세 */
    DEPARTMENT_DETAIL: "DEPARTMENT_DETAIL",
    INTERVIEW_INDEX: "INFOMATION_INTERVIEW_INDEX",
    INTERVIEW_TYPE: "INFOMATION_INTERVIEW_TYPE",
    INTERVIEW_DEPARTMENT: "INTERVIEW_DEPARTMENT",
    PASS_INDEX: "PASS_INDEX",
    PASS_TYPE: "PASS_TYPE",
    PASS_DEPARTMENT: "PASS_DEPARTMENT",
    PASS_PRINT: "PASS_PRINT",
    /** 대학정보 */
    UNIVERSITY_INDEX: "UNIVERSITY_INDEX",
    /** S1 Plus 정보 */
    SUCCESSSTRATEGY: "SUCCESSSTRATEGY",
    /** 대학정보 상세 */
    UNIVERSITY_UNIV_DETAIL: "UNIVERSITY_UNIV_DETAIL",
    UNIVERSITY_COLLEGE: "UNIVERSITY_COLLEGE",
    UNIVERSITY_COLLEGE_DETAIL: "UNIVERSITY_COLLEGE_DETAIL",
    SCREENING_DETAIL: "SCREENING_DETAIL",
    SUCCESSSTRATEGY_DETAIL: "SUCCESSSTRATEGY_DETAIL",
    /** 청사진 */
    PICTURE_FIELD: "PICTURE_FIELD",
    PICTURE_DEPARTMENT: "PICTURE_DEPARTMENT",
  },
  CONSULT: {
    INDEX: "CONSULT_INDEX",
    GRADE: "CONSULT_GRADE",
    UNIVERSITY: "CONSULT_UNIVERSITY",
  },
  REGULAR_CONSULT: {
    INDEX: "REGULAR_CONSULT_INDEX",
    GRADE: "REGULAR_CONSULT_GRADE",
  },
  ROADMAP_CONSULT: {
    UNIV_METHOD_INDEX: "ROADMAP_CONSULT_UNIV_METHOD_INDEX",
    UNIV_MEHTOD_SCHEDULE: "ROADMAP_CONSULT_UNIV_METHOD_SCHEDULE",
    SUSI_CONSULTING_RECRUITMENT: "ROADMAP_CONSULT_SUSI_CONSULTING_RECRUITMENT",
    SUSI_CONSULTING_MULTIPLESUPPORT:
      "ROADMAP_CONSULT_SUSI_CONSULTING_MULTIPLESUPPORT",
    SUSI_CONSULTING_COLLEGESCHEDULE:
      "ROADMAP_CONSULT_SUSI_CONSULTING_COLLEGESCHEDULE",
    SUSI_CONSULTING_ADIGASCORE: "ROADMAP_CONSULT_SUSI_CONSULTING_ADIGASCORE",
    JUNGSI_CONSULTING_ADMISSIONSTIMELINE:
      "ROADMAP_CONSULT_JUNGSI_CONSULTING_ADMISSIONSTIMELINE",
  },
  SIMULATOR: {
    INDEX: "SIMULATOR_INDEX",
    REPORT: "SIMULATOR_REPORT",
  },
  STUDENT_CONSULT: {
    INDEX: "STUDENT_CONSULT_INDEX",
    GRADE: "STUDENT_CONSULT_GRADE",
    UNIVERSITY: "STUDENT_CONSULT_UNIVERSITY",
  },
  INTERVIEW: {
    INDEX: "INTERVIEW_INDEX",
    INTERVIEW: "INTERVIEW_INTERVIEW",
    INTERVIEWER: "INTERVIEW_INTERVIEWER",
    NOTICE: "INTERVIEW_NOTICE",
    TEST: "INTERVIEW_TEST",
    TYPE: "INTERVIEW_TYPE",
    DOCUMENT_INDEX: "INTERVIEW_DOCUMENT_INDEX",
    DOCUMENT_INTRO: "INTERVIEW_DOCUMENT_INTRO",
    NORMAL_INTRO: "INTERVIEW_NORMAL_INTRO",
    DOCUMENT_REPORT: "INTERVIEW_DOCUMENT_REPORT",
    STATEMENT_INDEX: "INTERVIEW_STATEMENT_INDEX",
    STATEMENT_REPORT: "INTERVIEW_STATEMENT_REPORT",
    STATEMENT_INTRO: "INTERVIEW_STATEMENT_INTRO",
    RESERVATION_INDEX: "INTERVIEW_RESERVATION_INDEX",
    RESERVATION_APPLY: "INTERVIEW_RESERVATION_APPLY",
    RESERVATION_HISTORY: "INTERVIEW_RESERVATION_HISTORY",
    STUDENTRESERVATION: "INTERVIEW_STUDENTRESERVATION",
    STUDENTRESERVATION_NOTICE: "INTERVIEW_STUDENTRESERVATION_NOTICE",
    STUDENTRESERVATION_TEST: "INTERVIEW_STUDENTRESERVATION_TEST",
    STUDENTRESERVATION_INTRO: "INRERVIEW_STUDENTRESERVATION_INTRO"
  },
  BOARD: {
    FILEROOM_DETAIL: "BOARD_FILEROOM_DETAIL",
    FILEROOM_LIST: "BOARD_FILEROOM_LIST",
    FILEROOM_WRITE: "BOARD_FILEROOM_WRITE",
    NOTICE_DETAIL: "BOARD_NOTICE_DETAIL",
    NOTICE_LIST: "BOARD_NOTICE_LIST",
    NOTICE_WRITE: "BOARD_NOTICE_WRITE",
    NOTICE_EDIT: "BOARD_NOTICE_EDIT",
    FAQ_DETAIL: "BOARD_FAQ_DETAIL",
    FAQ_LIST: "BOARD_FAQ_LIST",
    FAQ_WRITE: "BOARD_FAQ_WRITE",
  },
  INTRO: {
    INDEX: "INTRO_INDEX",
    INFORMATION: "INTRO_INFORMATION",
    ENTRANCE: "INTRO_ENTRANCE",
    INTERVIEW: "INTRO_INTERVIEW",
  },
  WORK: {
    S1_LIST: "S1_LIST",
    S1_CREATE: "S1_CREATE",
    S1_DETAIL: "S1_DETAIL",
    S2_LIST: "S2_LIST",
    S2_CREATE: "S2_CREATE",
    S2_DETAIL: "S2_DETAIL",
    CONSULTING: "CONSULTING",
    CONSULTING_CREATE01: "CONSULTING.CREATE01",
    CONSULTING_CREATE02: "CONSULTING.CREATE02",
    CONSULTING_CREATE03: "CONSULTING.CREATE03",
    CONSULTING_CREATE04: "CONSULTING.CREATE04",
    CONSULTING_DETAIL01: "CONSULTING.DETAIL01",
    CONSULTING_DETAIL02: "CONSULTING.DETAIL02",
    CONSULTING_DETAIL03: "CONSULTING.DETAIL03",
    CONSULTING_DETAIL04: "CONSULTING.DETAIL04",
  },
}
