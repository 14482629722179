import { API_LOG_TYPE } from "@/constants/api"
import { useUserStore } from "@/store"
import { getRandomColor } from "@/utils/dev"
import { v4 as uuid } from "uuid"

/**
 *
 * @param {import("axios").AxiosInstance} instance
 */
export function setLogInterceptor(instance) {
  instance.interceptors.request.use((config) => {
    config.requestId = uuid().split("-")[0]
    config.color = getRandomColor(100, 150)

    const { tokenType, accessToken } = useUserStore()
    config.headers.Authorization = `${tokenType} ${accessToken}`

    const { requestId, color, url, data, headers, method } = config

    log({
      type: API_LOG_TYPE.REQ,
      requestId,
      color,
      url,
      data,
      method: method.toUpperCase(),
    })

    return config
  })

  instance.interceptors.response.use((response) => {
    const { requestId, color, method, url } = response.config
    log({
      type: API_LOG_TYPE.RES,
      requestId,
      color,
      url,
      data: response.data,
      method: method.toUpperCase(),
    })
    return response
  })
}

function log({ type, method, color, requestId, url, data, headers }) {
  const typeColor =
    type === API_LOG_TYPE.REQ
      ? "steelblue"
      : type === API_LOG_TYPE.RES
      ? "seagreen"
      : type === API_LOG_TYPE.WARN
      ? "orange"
      : type === API_LOG_TYPE.ERROR
      ? "red"
      : "black"
  console.log(
    `%c[${type}-${requestId}]%c [${method}] ${url}`,
    `background-color: ${typeColor}; color: white`,
    `color: ${color}`,
    data,
    headers,
  )
}
