import {api} from "@/api"
import {router} from "@/router"
import {popupEpki} from "@/utils/epki"
import {computed} from "@vue/reactivity"
import {defineStore} from "pinia"
import {ref} from "vue"

export const useUserStore = defineStore("user", () => {
    const user = ref()
    const accessToken = ref("")
    const refreshToken = ref("")
    const tokenType = ref("")
    const epkiInfo = ref()
    const phoneInfo = ref()
    const snsInfo = ref()

    const userId = computed(() => user.value?.userId || "")
    const scholId = computed(() => user.value?.schoolId || "")
    const userEmail = computed(() => user.value?.email)
    const isLoggedIn = computed(() => !!user.value)
    const isStudent = computed(() => user.value?.group === "01")
    const isParent = computed(() => user.value?.group === "02")
    const isTeacher = computed(() => user.value?.group === "03")
    const isAdmin = computed(() => user.value?.group === "99")

    function setLoginUser(payload) {
        user.value = payload.loginUser.user
        accessToken.value = payload.accessToken
        refreshToken.value = payload.refreshToken
        tokenType.value = payload.tokenType
        localStorage.accessToken = payload.accessToken
    }

    function setLogout() {
        user.value = undefined
        accessToken.value = ""
        refreshToken.value = ""
        tokenType.value = ""
        localStorage.accessToken = null
    }

    async function checkTeacher(email) {
        const result = await api.login.checkTeacher({email})

        if (!result.data.success) return
        if (result.data.errorCode !== "01" && result.data.errorCode !== "02") return

        const message = result.data.errorCode === "01" ? "교직원 인증서가 존재하지 않습니다.\nPC에서 인증서를 등록 하세요." : "교직원 인증서가 만료 되었습니다.\nPC에서 인증서를 재등록 하세요."

        alert(message)

        const params = await popupEpki({isLogin: true})

        if (!params.success) throw new Error("인증서 확인에 실패하였습니다.")

        return params
    }

    function setEpkiInfo(info) {
        epkiInfo.value = info
    }

    function setPhoneInfo(info) {
        phoneInfo.value = info
    }

    function setSnsInfo(info) {
        snsInfo.value = info
    }

    // async function login(email, password, next = "/main") {
    //   if (email) {
    //     const params = await checkTeacher(email)
    //     setEpkiInfo(params)
    //   }

    //   const result = await api.login.login({
    //     email,
    //     password,
    //     isSnsLogin: snsInfo.value?.isSnsLogin,
    //     appId: snsInfo.value?.appId,
    //     snsType: snsInfo.value?.snsType,
    //   })

    //   if (result.data.success) {
    //     setLoginUser(result.data)
    //     setSnsInfo(null)
    //     setPhoneInfo(null)
    //     router.push(next)
    //   } else {
    //     alert(
    //       "아이디(이메일) 또는 비밀번호를 잘못 입력했습니다.\n입력하신 내용을 다시 확인해주세요.",
    //     )
    //   }
    // }

    // async function loginTeacher(email, password, next = "/main") {
    //     // if (email) {
    //     //   const params = await checkTeacher(email)
    //     //   setEpkiInfo(params)
    //     // }
    //     const result = await api.teacher.login({
    //         email, password,
    //     })
    //
    //     if (result.data.success) {
    //         setLoginUser(result.data)
    //         router.push(next)
    //     } else {
    //         alert("아이디(이메일) 또는 비밀번호를 잘못 입력했습니다.\n입력하신 내용을 다시 확인해주세요.",)
    //     }
    // }

    // async function loginTeacher(email, password, next = "/main") {
    //     const MAX_ATTEMPTS = 5;
    //     const WAIT_TIME_MS = 2 * 60 * 1000; // 2분
    //
    //     //epki 로그인시 사용
    //     // if (email) {
    //     //   const params = await checkTeacher(email)
    //     //   setEpkiInfo(params)
    //     // }
    //
    //     // 현재 시각
    //     const now = new Date().getTime();
    //
    //     // 세션스토리지에서 로그인 시도 정보 가져오기
    //     let loginAttempts = JSON.parse(sessionStorage.getItem('teacherLoginAttempts')) || { count: 0, timestamp: now };
    //
    //     // 만약 시도 횟수가 최대 시도 횟수를 초과했으면
    //     if (loginAttempts.count >= MAX_ATTEMPTS) {
    //         // 대기 시간이 지나지 않았다면 경고 및 종료
    //         if (now - loginAttempts.timestamp < WAIT_TIME_MS) {
    //             alert("로그인 시도가 너무 많습니다. 2분 후에 다시 시도해 주세요.");
    //             return;
    //         } else {
    //             // 대기 시간이 지나면 시도 횟수 초기화
    //             loginAttempts = { count: 0, timestamp: now };
    //         }
    //     }
    //
    //     let result = null;
    //
    //     try {
    //         result = await api.teacher.login({ email, password });
    //         console.log(result); // 결과 로그 출력하여 확인
    //
    //         if (result.data.success) {
    //             setLoginUser(result.data);
    //             router.push(next);
    //             // 성공하면 시도 횟수 초기화
    //             sessionStorage.removeItem('teacherLoginAttempts');
    //         }
    //
    //     } catch (error) {
    //         console.error('API 호출 중 오류 발생:', error);
    //         // 실패하면 시도 횟수 증가
    //         loginAttempts.count += 1;
    //         loginAttempts.timestamp = now;
    //         sessionStorage.setItem('teacherLoginAttempts', JSON.stringify(loginAttempts));
    //
    //         if (loginAttempts.count >= MAX_ATTEMPTS) {
    //             alert("로그인 시도가 너무 많습니다. 2분 후에 다시 시도해 주세요.");
    //         } else {
    //             alert("아이디(이메일) 또는 비밀번호를 잘못 입력했습니다.\n입력하신 내용을 다시 확인해주세요.");
    //         }
    //     }
    // }
    //
    //
    // async function loginStudent(email, password, next = "/main") {
    //     const MAX_ATTEMPTS = 5;
    //     const WAIT_TIME_MS = 2 * 60 * 1000; // 2분
    //
    //     // 현재 시각
    //     const now = new Date().getTime();
    //
    //     // 로컬스토리지에서 로그인 시도 정보 가져오기
    //     let loginAttempts = JSON.parse(sessionStorage.getItem('loginAttempts')) || {count: 0, timestamp: now};
    //
    //     // 만약 시도 횟수가 최대 시도 횟수를 초과했으면
    //     if (loginAttempts.count >= MAX_ATTEMPTS) {
    //         // 대기 시간이 지나지 않았다면 경고 및 종료
    //         if (now - loginAttempts.timestamp < WAIT_TIME_MS) {
    //             alert("로그인 시도가 너무 많습니다. 2분 후에 다시 시도해 주세요.");
    //             return;
    //         } else {
    //             // 대기 시간이 지나면 시도 횟수 초기화
    //             loginAttempts = {count: 0, timestamp: now};
    //         }
    //     }
    //
    //     let result = null;
    //
    //     try {
    //         result = await api.student.login({ email, password });
    //         console.log(result); // 결과 로그 출력하여 확인
    //
    //         if (result.data.success) {
    //             setLoginUser(result.data);
    //             router.push(next);
    //             // 성공하면 시도 횟수 초기화
    //             sessionStorage.removeItem('loginAttempts');
    //         }
    //
    //     } catch (error) {
    //         console.error('API 호출 중 오류 발생:', error);
    //         // 실패하면 시도 횟수 증가
    //         loginAttempts.count += 1;
    //         loginAttempts.timestamp = now;
    //         sessionStorage.setItem('loginAttempts', JSON.stringify(loginAttempts));
    //
    //         if (loginAttempts.count >= MAX_ATTEMPTS) {
    //             alert("로그인 시도가 너무 많습니다. 2분 후에 다시 시도해 주세요.");
    //         } else {
    //             console.log(error)
    //             alert(error.response.data.message);
    //         }
    //     }
    // }

    //교사 로그인
    async function loginTeacher(email, password, next = "/main") {
        //epki 로그인시 사용
        // if (email) {
        //   const params = await checkTeacher(email)
        //   setEpkiInfo(params)
        // }

        await login(email, password, next, 'teacher', api.teacher.login, 'teacherLoginAttempts');
    }

    //학생 로그인
    async function loginStudent(email, password, next = "/main") {
        await login(email, password, next, 'student', api.student.login, 'studentLoginAttempts');
    }

    async function login(email, password, next, userType, apiLoginMethod, storageKey) {
        const MAX_ATTEMPTS = 4;
        const WAIT_TIME_MS = 2 * 60 * 1000; // 2분

        // 현재 시각
        const now = new Date().getTime();

        // 세션스토리지에서 로그인 시도 정보 가져오기
        let loginAttempts = JSON.parse(sessionStorage.getItem(storageKey)) || { count: 0, timestamp: now };

        // 만약 시도 횟수가 최대 시도 횟수를 초과했으면
        if (loginAttempts.count >= MAX_ATTEMPTS) {
            // 대기 시간이 지나지 않았다면 경고 및 종료
            if (now - loginAttempts.timestamp < WAIT_TIME_MS) {
                alert("로그인 시도가 너무 많습니다. 2분 후에 다시 시도해 주세요.");
                return;
            } else {
                // 대기 시간이 지나면 시도 횟수 초기화
                loginAttempts = { count: 0, timestamp: now };
            }
        }

        let result = null;

        try {
            result = await apiLoginMethod({ email, password });
            console.log(result); // 결과 로그 출력하여 확인

            if (result.data.success) {
                setLoginUser(result.data);
                router.push(next);
                // 성공하면 시도 횟수 초기화
                sessionStorage.removeItem(storageKey);
            }
        } catch (error) {
            console.error('API 호출 중 오류 발생:', error);
            // 실패하면 시도 횟수 증가
            loginAttempts.count += 1;
            loginAttempts.timestamp = now;
            sessionStorage.setItem(storageKey, JSON.stringify(loginAttempts));

            if (loginAttempts.count >= MAX_ATTEMPTS) {
                alert("로그인 시도가 너무 많습니다. 2분 후에 다시 시도해 주세요.");
            } else {
                alert(error.response.data.message);
            }
        }
    }



    // async function loginStudent(email, password, next = "/main") {
    //   const result = await api.student.login({
    //     email,
    //     password,
    //   })
    //
    //   if (result.data.success) {
    //     setLoginUser(result.data)
    //     router.push(next)
    //   } else {
    //     alert(
    //       "아이디(이메일) 또는 비밀번호를 잘못 입력했습니다.\n입력하신 내용을 다시 확인해주세요.",
    //     )
    //   }
    // }

    async function logout() {
        const result = await api.login.logout({email: userEmail.value})

        if (result.data.success) {
            setLogout()
            router.push("/main")
        } else {
            alert(result.data.message || "로그아웃에 실패하였습니다.")
        }
    }

    return {
        epkiInfo,
        phoneInfo,
        snsInfo,
        user,
        tokenType,
        accessToken,
        refreshToken,
        userId,
        scholId,
        isAdmin,
        isLoggedIn,
        isStudent,
        isParent,
        isTeacher,
        loginTeacher,
        loginStudent,
        logout,
        setEpkiInfo,
        setPhoneInfo,
        setSnsInfo,
    }
}, {
    persist: {
        storage: sessionStorage,
    },
},)
