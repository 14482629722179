import { ROUTES } from "@/constants/routes"
import {ipMiddleware, loginMiddleware} from "@/router/_middleware.js";

export const informationRoutes = [
  {
    path: "/information/summaryTable",
    name: ROUTES.INFOMATION.SUMMARY_TABLE,
    component: () => import("@/views/information/SummaryTable.vue"),
    beforeEnter: [],
  },
  {
    path: "/information/regularSummaryTable",
    name: ROUTES.INFOMATION.REGULAR_SUMMARY_TABLE,
    component: () => import("@/views/information/RegularSummaryTable.vue"),
    beforeEnter: [],
  },
  {
    path: "/information/department",
    name: ROUTES.INFOMATION.DEPARTMENT_INDEX,
    component: () => import("@/views/information/department/Index.vue"),
    beforeEnter: [],
  },
  {
    path: "/information/department/detail",
    name: ROUTES.INFOMATION.DEPARTMENT_DETAIL,
    component: () => import("@/views/information/department/Detail.vue"),
    beforeEnter: [],
  },
  {
    path: "/information/interview",
    name: ROUTES.INFOMATION.INTERVIEW_INDEX,
    component: () => import("@/views/information/interview/Index.vue"),
    beforeEnter: [ipMiddleware],
  },
  {
    path: "/information/interview/newWindow/print",
    name: ROUTES.INFOMATION.INTERVIEW_PRINT,
    component: () => import("@/containers/information/interview/IndexInterviewPrint.vue"),
    beforeEnter: [ipMiddleware],
  },
  {
    path: "/information/pass",
    name: ROUTES.INFOMATION.PASS_INDEX,
    component: () => import("@/views/information/pass/Index.vue"),
    beforeEnter: [loginMiddleware, ipMiddleware],
  },
  {
    path: "/information/pass/newWindow/print",
    name: ROUTES.INFOMATION.PASS_PRINT,
    component: () => import("@/containers/information/pass/IndexPassPrint.vue"),
    beforeEnter: [loginMiddleware, ipMiddleware],
  },
  {
    //사용안함.
    path: "/information/pass/type",
    name: ROUTES.INFOMATION.PASS_TYPE,
    component: () => import("@/views/information/pass/Type.vue"),
    beforeEnter: [loginMiddleware, ipMiddleware],
  },
  {
    path: "/information/pass/department",
    name: ROUTES.INFOMATION.PASS_DEPARTMENT,
    component: () => import("@/views/information/pass/Department.vue"),
    beforeEnter: [loginMiddleware, ipMiddleware],
  },
  {
    path: "/information/university",
    name: ROUTES.INFOMATION.UNIVERSITY_INDEX,
    component: () => import("@/views/information/university/Index.vue"),
    beforeEnter: [],
  },
  {
    path: "/information/university/college",
    name: ROUTES.INFOMATION.UNIVERSITY_COLLEGE,
    component: () => import("@/views/information/university/College.vue"),
    beforeEnter: [],
  },
  {
    path: "/information/university/collegeDetail",
    name: ROUTES.INFOMATION.UNIVERSITY_COLLEGE_DETAIL,
    component: () => import("@/views/information/university/CollegeDetail.vue"),
    beforeEnter: [],
  },
  {
    path: "/information/university/univDetail",
    name: ROUTES.INFOMATION.UNIVERSITY_UNIV_DETAIL,
    component: () => import("@/views/information/university/UnivDetail.vue"),
    beforeEnter: [],
  },
  {
    path: "/information/university/screeningDetail",
    name: ROUTES.INFOMATION.SCREENING_DETAIL,
    component: () =>
      import("@/views/information/university/ScreeningDetail.vue"),
    beforeEnter: [],
  },
  {
    path: "/information/successStrategy",
    name: ROUTES.INFOMATION.SUCCESSSTRATEGY,
    component: () => import("@/views/information/successStrategy/Index.vue"),
    beforeEnter: [],
  },
  {
    path: "/information/successStrategy/detail",
    name: ROUTES.INFOMATION.SUCCESSSTRATEGY_DETAIL,
    component: () => import("@/views/information/successStrategy/Detail.vue"),
    beforeEnter: [],
  },
  {
    path: "/information/picture/field",
    name: ROUTES.INFOMATION.PICTURE_FIELD,
    component: () => import("@/views/information/picture/Field.vue"),
    beforeEnter: [],
  },
  {
    path: "/information/picture/department",
    name: ROUTES.INFOMATION.PICTURE_DEPARTMENT,
    component: () => import("@/views/information/picture/Department.vue"),
    beforeEnter: [],
  },
]
