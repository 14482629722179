<script setup>
import { api } from "@/api"
import { ref, computed, defineAsyncComponent } from "vue"
import { useRoute } from "vue-router"
import { ROUTES } from "./constants/routes"

import { useMenuStore, useInterviewStore } from "@/store"
import { onBeforeMount } from "vue"

const interviewStore = useInterviewStore()
const menuStore = useMenuStore()
const route = useRoute()

onBeforeMount(() => {
  menuStore.fetchMenuItems()
  fetchReport()
})

const layoutMap = {
  DefaultLayout: defineAsyncComponent(() =>
    import("@/layouts/DefaultLayout.vue"),
  ),
  MainLayout: defineAsyncComponent(() => import("@/layouts/MainLayout.vue")),
  PlainLayout: defineAsyncComponent(() => import("@/layouts/PlainLayout.vue")),
}
const layout = computed(() => route.meta?.layout || "DefaultLayout")
const layoutComponent = computed(() => layoutMap[layout.value])

const keepAliveComponents = ref([
  ROUTES.INFOMATION.UNIVERSITY_INDEX,
  ROUTES.INFOMATION.UNIVERSITY_COLLEGE,
  ROUTES.INFOMATION.DEPARTMENT_INDEX,
  ROUTES.INFOMATION.PASS_INDEX,
  ROUTES.INFOMATION.PASS_DEPARTMENT,
  ROUTES.INFOMATION.PASS_TYPE,
  ROUTES.INFOMATION.INTERVIEW_INDEX,
  ROUTES.INFOMATION.INTERVIEW_DEPARTMENT,
  ROUTES.INFOMATION.INTERVIEW_TYPE,
])

async function fetchReport() {
  let params = localStorage.getItem('savedParams');
  let group_key = localStorage.getItem('groupKey');
  if (params || group_key) {
    try {
      if (params) {
        await api.test.aiReport.saveAiReportHistory(JSON.parse(params));
      }
      if (group_key) {
        const res = await interviewStore.fetchCompleteAiReport(group_key);
        interviewStore.completeAiReportResult = res;
      }
    } catch (error) {
      alert(error.message);
    }
  }
}


</script>

<template >
  <component :is="layoutComponent">
    <router-view v-slot="{ Component }">
      <keep-alive :max="3" :include="keepAliveComponents">
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </component>
</template>

<style lang="scss">
@media print {
  header,
  footer,
  section.banner-section {
    display: none;
  }

  .no-print {
    display: none;

    & * {
      display: none;
    }
  }
}
</style>
