export function delay(time) {
  return new Promise((resolve) => setTimeout(resolve, time))
}

export function groupBy(arr, key) {
  return arr.reduce((acc, cur) => {
    const targetValue = cur[key]

    if (acc[targetValue]) acc[targetValue].push(cur)
    else acc[targetValue] = [cur]

    return acc
  }, {})
}

export function isTruthy(value) {
  return !!value
}

export function first(arr) {
  return arr[0]
}
export function last(arr) {
  return arr[arr.length - 1]
}

export function debounce(f, delay = 0) {
  let timeoutId
  return (...args) => {
    // 실행한 함수(setTimeout())를 취소
    clearTimeout(timeoutId)
    // delay가 지나면 callback 함수를 실행
    timeoutId = setTimeout(() => f(...args), delay)
  }
}

export function noop() {}
