import { ROUTES } from "@/constants/routes"
import { ipMiddleware, nonLoginMiddleware } from "./_middleware"

export const loginRoutes = [
  {
    path: "/login/login",
    name: ROUTES.LOGIN.INDEX,
    component: () => import("@/views/login/Login.vue"),
    beforeEnter: [nonLoginMiddleware],
  },
  {
    path: "/login/findid",
    name: ROUTES.LOGIN.FIND_ID,
    component: () => import("@/views/login/FindId.vue"),
    beforeEnter: [nonLoginMiddleware],
  },
  {
    path: "/login/findidStudent",
    name: ROUTES.LOGIN.FIND_ID_STUDENT,
    component: () => import("@/views/login/FindIdStudent.vue"),
    beforeEnter: [nonLoginMiddleware],
  },
  {
    path: "/login/findidTeacher",
    name: ROUTES.LOGIN.FIND_ID_TEACHER,
    component: () => import("@/views/login/FindIdTeacher.vue"),
    beforeEnter: [nonLoginMiddleware],
  },
  {
    path: "/login/findpw",
    name: ROUTES.LOGIN.FIND_PW,
    component: () => import("@/views/login/FindPw.vue"),
    beforeEnter: [nonLoginMiddleware],
  },
  {
    path: "/login/resetPassword",
    name: ROUTES.LOGIN.RESET_PASSWORD,
    component: () => import("@/views/login/ResetPassword.vue"),
    beforeEnter: [nonLoginMiddleware],
  },
  {
    path: "/login/setPassword",
    name: ROUTES.LOGIN.SET_PASSWORD,
    component: () => import("@/views/login/SetPassword.vue"),
    beforeEnter: [nonLoginMiddleware],
  },
  {
    path: "/login/epki",
    name: ROUTES.LOGIN.EPKI,
    component: () => import("@/views/login/Epki.vue"),
    beforeEnter: [nonLoginMiddleware],
  },

  {
    path: "/login/join",
    name: ROUTES.LOGIN.JOIN_INDEX,
    component: () => import("@/views/login/join/Index.vue"),
    beforeEnter: [ipMiddleware, nonLoginMiddleware],
  },
  {
    path: "/login/join/step01",
    name: ROUTES.LOGIN.JOIN_STEP01,
    component: () => import("@/views/login/join/Step01.vue"),
    beforeEnter: [ipMiddleware, nonLoginMiddleware],
  },
  {
    path: "/login/join/step02",
    name: ROUTES.LOGIN.JOIN_STEP02,
    component: () => import("@/views/login/join/Step02.vue"),
    beforeEnter: [ipMiddleware, nonLoginMiddleware],
  },
  {
    path: "/login/join/step03_1",
    name: ROUTES.LOGIN.JOIN_STEP03_1,
    component: () => import("@/views/login/join/Step03_1.vue"),
    beforeEnter: [ipMiddleware, nonLoginMiddleware],
  },
  {
    path: "/login/join/step03_2",
    name: ROUTES.LOGIN.JOIN_STEP03_2,
    component: () => import("@/views/login/join/Step03_2.vue"),
    beforeEnter: [ipMiddleware, nonLoginMiddleware],
  },
  {
    path: "/login/join/step04",
    name: ROUTES.LOGIN.JOIN_STEP04,
    component: () => import("@/views/login/join/Step04.vue"),
    beforeEnter: [ipMiddleware, nonLoginMiddleware],
  },
  {
    path: "/login/join/step05",
    name: ROUTES.LOGIN.JOIN_STEP05,
    component: () => import("@/views/login/join/Step05.vue"),
    beforeEnter: [ipMiddleware, nonLoginMiddleware],
  },
  {
    path: "/login/join/callbackCertEpki",
    name: ROUTES.LOGIN.CALLBACK_EPKI,
    component: () => import("@/views/login/join/CallbackCertEpki.vue"),
    beforeEnter: [ipMiddleware, nonLoginMiddleware],
  },
  {
    path: "/login/join/callbackCertPhone",
    name: ROUTES.LOGIN.CALLBACK_PHONE,
    component: () => import("@/views/login/join/CallbackCertPhone.vue"),
    beforeEnter: [ipMiddleware],
  },
  {
    path: "/login/join/callbackKakao",
    name: ROUTES.LOGIN.CALLBACK_KAKAO,
    component: () => import("@/views/login/join/CallbackKakao.vue"),
    beforeEnter: [nonLoginMiddleware],
  },
  {
    path: "/login/join/callbackNaver",
    name: ROUTES.LOGIN.CALLBACK_NAVER,
    component: () => import("@/views/login/join/CallbackNaver.vue"),
    beforeEnter: [nonLoginMiddleware],
  },
  {
    path: "/login/jointeacher/step01",
    name: ROUTES.LOGIN.JOIN_TEACHER_STEP01,
    component: () => import("@/views/login/join_teacher/step01.vue"),
    beforeEnter: [nonLoginMiddleware],
  },
  {
    path: "/login/jointeacher/step02",
    component: () => import("@/views/login/join_teacher/step02.vue"),
    name: ROUTES.LOGIN.JOIN_TEACHER_STEP02,
    beforeEnter: [nonLoginMiddleware],
  },
  {
    path: "/login/jointeacher/step03",
    component: () => import("@/views/login/join_teacher/step03.vue"),
    name: ROUTES.LOGIN.JOIN_TEACHER_STEP03,
    beforeEnter: [nonLoginMiddleware],
  },
  {
    path: "/login/jointeacher/step04",
    component: () => import("@/views/login/join_teacher/step04.vue"),
    name: ROUTES.LOGIN.JOIN_TEACHER_STEP04,
    beforeEnter: [nonLoginMiddleware],
  },
]
