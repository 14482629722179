import { api } from "@/api"
import { first, groupBy } from "@/utils/util"
import { defineStore } from "pinia"
import { computed } from "vue"
import { ref } from "vue"
import { useRouter } from "vue-router"

export const useMenuStore = defineStore("menu", function () {
  const router = useRouter()
  const items = ref([])

  const grouped = computed(() => groupBy(items.value, "parentMenuId"))

  function moveMenu(menuId) {
    const item = items.value.find((item) => item.menuId == menuId)
    if (!item) return
    if (item.url) return router.push(item.url)

    const children = grouped.value[item.menuId]
    if (!children) return

    const firstChild = first(children)
    if (!firstChild) return
    return moveMenu(firstChild.menuId)
  }

  async function fetchMenuItems() {
    const result = await api.menu.selectMenuList()

    items.value = result.data

    const status = result.status // 상태 값에 따라 처리해야 할 수 있습니다.
    if (status === 403) {
      // 200 상태 값에 따른 처리
      router.push("/403Error")
    } else if (status === 404) {
      // 404 상태 값에 따른 처리
      router.push("/404Error")
    } else if (status === 500) {
      // 500 상태 값에 따른 처리
      router.push("/500Error")
    } else if (status === 503) {
      // 500 상태 값에 따른 처리
      router.push("/503Error")
    }
  }

  return { items, grouped, moveMenu, fetchMenuItems }
})
