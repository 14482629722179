import { ROUTES } from "@/constants/routes"

export const introRoutes = [
  {
    path: "/intro",
    name: ROUTES.INTRO.INDEX,
    component: () => import("@/views/intro/Index.vue"),
  },
  {
    path: "/intro/information",
    name: ROUTES.INTRO.INFORMATION,
    component: () => import("@/views/intro/Information.vue"),
  },
  {
    path: "/intro/entrance",
    name: ROUTES.INTRO.ENTRANCE,
    component: () => import("@/views/intro/Entrance.vue"),
  },
  {
    path: "/intro/interview",
    name: ROUTES.INTRO.INTERVIEW,
    component: () => import("@/views/intro/Interview.vue"),
  },
]
