import { defineStore } from "pinia"
import { ref } from "vue"

export const useSimulatorStore = defineStore(
  "simulator",
  () => {
    const result = ref({})

    return { result }
  },
  {
    persist: {
      storage: sessionStorage,
    },
  },
)
