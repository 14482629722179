import { createRouter, createWebHistory } from "vue-router"
import { entranceRoutes } from "./entrance"
import { interviewRoutes } from "./interview"
import { informationRoutes } from "./information"
import { loginRoutes } from "./login"
import { boardRoutes } from "./board"
import { ROUTES } from "@/constants/routes"
import { introRoutes } from "./intro"
import { mypageRoutes } from "./mypage"
import { workRoutes } from "./work"
import { ipMiddleware, loginMiddleware } from "@/router/_middleware.js"

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "INDEX",
      redirect: "/main",
    },
    {
      path: "/main",
      name: ROUTES.MAIN.INDEX,
      component: () => import("@/views/Main.vue"),
      meta: { layout: "MainLayout" },
    },
    {
      path: "/waiting",
      name: ROUTES.MAIN.WAITING,
      component: () => import("@/views/Prepare.vue"),
      beforeEnter: [ipMiddleware, loginMiddleware],
    },

    ...loginRoutes,
    ...mypageRoutes,
    ...entranceRoutes,
    ...interviewRoutes,
    ...informationRoutes,
    ...boardRoutes,
    ...introRoutes,
    ...workRoutes,
    {
      path: "/tbd",
      component: () => import("@/views/error/TBD.vue"),
    },
    {
      path: "/:pathMatch(.*)",
      component: () => import("@/views/error/NotFound.vue"),
    },
    {
      path: "/403Error",
      component: () => import("@/views/error/403.vue"),
    },
    {
      path: "/404Error",
      component: () => import("@/views/error/404.vue"),
    },
    {
      path: "/500Error",
      component: () => import("@/views/error/500.vue"),
    },
    {
      path: "/503Error",
      component: () => import("@/views/error/503.vue"),
    },
  ],

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})