<template>
  <div>
    <router-view :key="$route.fullPath" id="routerContent"></router-view>
  </div>
</template>

<script>
export default {
  name: "NewWindowApp",
}
</script>
