import {ROUTES} from "@/constants/routes"

export const workRoutes = [
  {
    path: "/work/s1/list",
    name: ROUTES.WORK.S1_LIST,
    component: () => import("@/views/work/s1/List.vue"),
    meta: { layout: "PlainLayout" },
  },
  {
    path: "/work/s1/create",
    name: ROUTES.WORK.S1_CREATE,
    component: () => import("@/views/work/s1/Create.vue"),
    meta: { layout: "PlainLayout" },
  },
  {
    path: "/work/s1/detail/:UNIV_CD",
    name: ROUTES.WORK.S1_DETAIL,
    component: () => import("@/views/work/s1/Detail.vue"),
    meta: { layout: "PlainLayout" },
  },
  {
    path: "/work/s2/list",
    name: ROUTES.WORK.S2_LIST,
    component: () => import("@/views/work/s2/List.vue"),
    meta: { layout: "PlainLayout" },
  },
  {
    path: "/work/s2/create",
    name: ROUTES.WORK.S2_CREATE,
    component: () => import("@/views/work/s2/Create.vue"),
    meta: { layout: "PlainLayout" },
  },
  {
    path: "/work/s2/detail/:UNIV_CD",
    name: ROUTES.WORK.S2_DETAIL,
    component: () => import("@/views/work/s2/Detail.vue"),
    meta: { layout: "PlainLayout" },
  },
  {
    path: "/work/consulting",
    name: ROUTES.WORK.CONSULTING,
    component: () => import("@/views/work/consulting/List.vue"),
    meta: { layout: "PlainLayout" },
  },
  {
    path: "/work/consulting/create01",
    name: ROUTES.WORK.CONSULTING_CREATE01,
    component: () => import("@/views/work/consulting/Create01.vue"),
    meta: { layout: "PlainLayout" },
  },
  {
    path: "/work/consulting/create02",
    name: ROUTES.WORK.CONSULTING_CREATE02,
    component: () => import("@/views/work/consulting/Create02.vue"),
    meta: { layout: "PlainLayout" },
  },
  {
    path: "/work/consulting/create03",
    name: ROUTES.WORK.CONSULTING_CREATE03,
    component: () => import("@/views/work/consulting/Create03.vue"),
    meta: { layout: "PlainLayout" },
  },
  {
    path: "/work/consulting/create04",
    name: ROUTES.WORK.CONSULTING_CREATE04,
    component: () => import("@/views/work/consulting/Create04.vue"),
    meta: { layout: "PlainLayout" },
  },
  {
    path: "/work/consulting/detail01",
    name: ROUTES.WORK.CONSULTING_DETAIL01,
    component: () => import("@/views/work/consulting/Detail01.vue"),
    meta: { layout: "PlainLayout" },
  },
  {
    path: "/work/consulting/detail02",
    name: ROUTES.WORK.CONSULTING_DETAIL02,
    component: () => import("@/views/work/consulting/Detail02.vue"),
    meta: { layout: "PlainLayout" },
  },
  {
    path: "/work/consulting/detail03",
    name: ROUTES.WORK.CONSULTING_DETAIL03,
    component: () => import("@/views/work/consulting/Detail03.vue"),
    meta: { layout: "PlainLayout" },
  },
  {
    path: "/work/consulting/detail04",
    name: ROUTES.WORK.CONSULTING_DETAIL04,
    component: () => import("@/views/work/consulting/Detail04.vue"),
    meta: { layout: "PlainLayout" },
  },
]
